export const getAllSector  = async (token) => {
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            token: token
        }
    }
    const url = 'https://app.intuety.io/api/v2/sectors'

    const res = await fetch(url, options)
    const regions = await res.json()
    return regions.data
}
