import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { Button, NavDropdown } from 'react-bootstrap'
import { useKeycloak } from '@react-keycloak/web'

function Navigation () {
    const { keycloak } = useKeycloak()

    const logoutButtonClick = () => {
        keycloak.logout()
    }

    const loginButtonClick = () => {
        keycloak.login()
    }

    return (
        <Navbar bg="light" expand="lg">
            <Nav>
                <Navbar.Brand href="/">Intuety Internal tools</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav.Item>
                        <Nav.Link as={Link} to='/dashboard'>Dashboard</Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <NavDropdown title="Administration">
                            <NavDropdown.Item><Nav.Link as={Link} to='/clients'>Clients</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/users">Users</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/regions">Regions</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/sectors">Sectors</Nav.Link></NavDropdown.Item>
                        </NavDropdown>
                    </Nav.Item>

                    <Nav.Item>
                        <NavDropdown title="Documents">
                            <NavDropdown.Item><Nav.Link as={Link} to='/status'>Status</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/test-documents">Test Documents</Nav.Link></NavDropdown.Item>
                        </NavDropdown>
                    </Nav.Item>
                    <Nav.Item>
                        <NavDropdown title="Triples">
                            <NavDropdown.Item><Nav.Link as={Link} to="/triples">Search Triples</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/add-triples">Add Triple</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/new-triples">New Triples</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/find-and-replace">Find and Replace</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/semantic-lookup">Semantic Lookup</Nav.Link></NavDropdown.Item>
                        </NavDropdown>
                    </Nav.Item>
                    <Nav.Item>
                        <NavDropdown title="Safety Briefings">
                            <NavDropdown.Item><Nav.Link as={Link} to="/safety-briefings">Search Safety Briefings</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/upload-safety-briefing">Upload Safety Briefing</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/safety-briefing-missing-fields">Missing Fields</Nav.Link></NavDropdown.Item>
                        </NavDropdown>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as={Link} to="/prohibited-equipment">Prohibited Equipment</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <NavDropdown title="Config">
                            <NavDropdown.Item><Nav.Link as={Link} to="/config">Intellegence Platform Config</Nav.Link></NavDropdown.Item>
                            <NavDropdown.Item><Nav.Link as={Link} to="/user-creation">User Creation</Nav.Link></NavDropdown.Item>
                        </NavDropdown>
                    </Nav.Item>
                    <Nav.Item>
                        {
                            keycloak.authenticated
                                ? (<Button variant="dark" onClick={logoutButtonClick}>Log Out</Button>)
                                : (<Button variant="light" onClick={loginButtonClick}>Log In</Button>)
                        }
                    </Nav.Item>
                </Navbar.Collapse>
            </Nav>
        </Navbar>
    )
}

export default Navigation
