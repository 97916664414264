import React, { useState } from 'react'
import styled from 'styled-components'
import TextField from './TextField'
import ArrayField from './ArrayField'
import { Button } from 'react-bootstrap'
import NewFieldModal from './NewFieldModal'

const Wrapper = styled.div`
    border: 2px black solid;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
`

function isArray (object) {
    return Object.prototype.toString.call(object) === '[object Array]'
}

function ObjectField ({ object, path, update, removeObject, environment }) {
    const [showModal, setShowModal] = useState(false)

    const addNewField = (name, type, environment) => {
        if (object[name]) {
            alert('Field already exists')
        } else if (/\s/g.test(name) || name.length === 0) {
            alert('Name must not include spaces or be empty')
        } else {
            if (type === 'object') {
                object[name] = {}
            } else {
                object[name] = ''
            }

            update(path, object, environment)
        }
    }

    const updateValue = (valuePath, value, env) => {
        const key = valuePath.slice(-1)
        object[key] = value
        update(path, object, env)
    }

    const deleteObject = () => {
        removeObject(path, environment)
    }

    const remove = (path) => {
        delete object[path.slice(-1)]
        update(path, object, environment)
    }

    const displayChildren = () => {
        const keys = Object.keys(object)
        return keys.map(key => {
            switch (typeof (object[key])) {
            case 'string':
                return <TextField objectKey={key} value={object[key]} path={path.concat([key])} environment={environment} update={updateValue} remove={remove} />
            case 'number':
                return <TextField objectKey={key} value={object[key]} path={path.concat([key])} environment={environment} update={updateValue} remove={remove} />
            case 'object':
                return <ObjectField object={object[key]} path={path.concat([key])} environment={environment} update={updateValue} />
            default:
                return <p>{typeof (object[key])}</p>
            }
        })
    }

    if (isArray(object)) {
        return (
            <Wrapper>
                <p>{path.slice(-1)[0]}</p>
                <ArrayField array={object} path={path} environment={environment} update={update} />
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <NewFieldModal show={showModal} setShow={setShowModal} environment={environment} addNewField={addNewField} />
                <p>{path.slice(-1)[0]}</p>
                {displayChildren()}
                <Button onClick={() => { setShowModal(true) }}>Add Field</Button>
                {Object.keys(object).length === 0 ? <Button variant="danger" onClick={deleteObject} style={{ marginLeft: '1em' }}>Delete Object</Button> : ''}
            </Wrapper>
        )
    }
}

export default ObjectField
