import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import TriplesSearchbar from './TriplesSearchbar'
import TripleSearch from './TripleSearch'
import TriplesTable from './TriplesTable'
import TriplesPageSelector from './TriplesPageSelector'
import DatabaseSelection from '../DatabaseSelection'
import DeleteModal from './DeleteModal'
import EditModal from './EditModal'

function TriplesChecker () {
    const [activity, setActivity] = useState('')
    const [risk, setRisk] = useState('')
    const [mitigation, setMitigation] = useState('')
    const [returnedInfo, setReturnedInfo] = useState('')
    const [enterHit, setEnterHit] = useState(false)
    const [page, setPage] = useState(1)
    const [newPage, setNewPage] = useState(false)
    const [db, setDB] = useState('Live Database')
    const [showDelete, setShowDelete] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [triple, setTriple] = useState({})
    const [_tripleIndex, setTripleIndex] = useState(null)
    const [useSemanticLookup, setUseSemanticLookup] = useState(false)
    const [elasticTotal, setElasticTotal] = useState(0)

    function logValues () {
        console.log(activity)
        console.log(risk)
        console.log(mitigation)
        console.log(returnedInfo)
    }

    function displayValues () {
        if (returnedInfo) {
            return <TriplesTable info={returnedInfo} setShowDelete={setShowDelete} setShowEdit={setShowEdit}
                setTriple={setTriple} setIndex={setTripleIndex} />
        } else {
            return <p id="nothing-display">Nothing to show</p>
        }
    }

    function displayPageSelector () {
        if (returnedInfo && !useSemanticLookup) {
            return (<TriplesPageSelector page={page} setPage={setPage} setNewPage={setNewPage} max={elasticTotal} />)
        }
    }

    function newSearch () {
        setPage(1)
        setEnterHit(true)
    }

    function reloadPage () {
        setEnterHit(true)
    }

    return (
        <Container>
            <Row>
                <Col><h1 id="title"> Search Triples </h1></Col>
                <Col><DatabaseSelection db={db} setDB={setDB} /></Col>
            </Row>
            <Row>
                <DeleteModal show={showDelete} setShow={setShowDelete} triple={triple} reloadSearch={reloadPage} db={db} />
                <EditModal show={showEdit} setShow={setShowEdit} triple={triple} db={db} />
            </Row>
            <Row>
                <Col><TriplesSearchbar placeholder='Activity' setValue={setActivity} newSearch={newSearch}></TriplesSearchbar></Col>
                <Col><TriplesSearchbar placeholder='Risk' setValue={setRisk} newSearch={newSearch}></TriplesSearchbar></Col>
                <Col><TriplesSearchbar placeholder='Mitigation' setValue={setMitigation} newSearch={newSearch}></TriplesSearchbar></Col>
            </Row>
            <Row>
                <input type="checkbox" id="useSemanticCheckbox" value={useSemanticLookup} onChange={() => { setUseSemanticLookup(!useSemanticLookup) }} style={{ margin: '6px' }}/>
                <label htmlFor="useSemanticCheckbox">Use Semantic Lookup</label>
            </Row>
            <Row id="triple-search-button">
                <TripleSearch logValues={logValues}
                    activity={activity} risk={risk} mitigation={mitigation} setReturnedInfo={setReturnedInfo}
                    enterHit={enterHit} setEnterHit={setEnterHit} startPoint={(page - 1) * 10} newPage={newPage}
                    setNewPage={setNewPage} db={db} useSemanticLookup={useSemanticLookup}
                    setElasticTotal={setElasticTotal}> Search </TripleSearch>
            </Row>
            <Row><br></br></Row>
            <Row>
                {displayValues()}
            </Row>
            <Row>
                {displayPageSelector()}
            </Row>
        </Container>
    )
}

export default TriplesChecker
