import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getAllClients } from '.'

export const ViewAllClientsButton = (props) => {
    const [clients, setClients] = useState([])

    const handleClose = () => { props.setShow(false) }

    useEffect(() => {
        const fetchClients = async () => {
            const res = await getAllClients()
            setClients(res)
        }

        fetchClients()
    })

    return (
        <>
            <Button onClick={() => { props.setShow(true) }} style={{ margin: '5px' }}>View All Clients</Button>
            <Modal show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Client Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table>
                        <thead>
                            <th>Client Id</th>
                            <th>Client Name</th>
                        </thead>
                        <tbody>
                            {clients.map(client => (
                                <tr key={client.clientId}>
                                    <td>{client.clientId}</td>
                                    <td>{client.clientName}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                </Modal.Body>
            </Modal>
        </>

    )
}
