import React from 'react'
import { Form, FormControl } from 'react-bootstrap'

function Searchbar (props) {
    function valueInput (event) {
        props.setValue(event.target.value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            props.newSearch()
        }
    }

    return (
        <Form inline>
            <FormControl type='text'
                placeholder={props.placeholder}
                className="mr-sm-2"
                onChange={valueInput}
                onKeyPress={handleKeyDown} />
        </Form>
    )
}

export default Searchbar
