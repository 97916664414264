function getFile (setFile, id, keycloak) {
    console.log('fetching file id:', id)
    const url = `https://api.intuety.io/aurora/api/safety-briefing/file/${id}`
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Auth-Token': keycloak.tokenParsed?.api_token
        }
    }
    fetch(url, options).then(response => {
        console.log('Successfully fetched')
        return response.blob()
    }).then(blob => {
        // Create a Blob from the PDF Stream
        const file = new Blob(
            [blob],
            { type: 'application/pdf' })
        const fileURL = URL.createObjectURL(file)
        // window.open(fileURL)
        setFile(fileURL)
    }).catch(error => {
        console.log(error)
    })
}

function getNextUntagged (field, setBriefing, setFile, oldID, keycloak) {
    console.log('fetching next briefing')
    const url = 'https://api.intuety.io/aurora/api/safety-briefing/fetch-missing-field'
    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Auth-Token': keycloak.tokenParsed?.api_token
        },
        body: JSON.stringify({
            MissingField: field
        })
    }
    fetch(url, options).then(response => {
        console.log('Successfly fetched')
        return response.json()
    }).then(data => {
        if (data.hits.hits[0] === undefined) {
            setFile('No file found')
            setBriefing({
                _id: 'No file found',
                _source: {
                    Title: 'No file found'
                }
            })
        } else if (data.hits.hits[0]._id === oldID) {
            throw new Error('Same ID')
        } else {
            setBriefing(data.hits.hits[0])

            getFile(setFile, data.hits.hits[0]._id, keycloak)
        }
    }).catch(error => {
        if (error === 'Same ID') {
            console.log('Same ID, refetching')
            setTimeout(getNextUntagged, 300, field, setBriefing, setFile, oldID, keycloak)
        } else {
            console.log('Error: ', error)
        }
    })
}

export default getNextUntagged
