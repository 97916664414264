import React, { useEffect, useState } from 'react'

import TripleCard from './TripleCard'

function TripleCards (props) {
    const [currentCard, setCurrentCard] = useState(0)
    const [maxCard, setMaxCard] = useState(0)

    function updateCurrentCard (card) {
        if (card <= maxCard - 1) {
            card++
            document.getElementById(card).style.display = 'initial'
            setCurrentCard(card)
        } else {
            props.setProcessed(true)
            setTimeout(() => { props.fetchTriples() }, 300)
        }
    }

    function getCurrentCard () {
        return currentCard
    }

    useEffect(() => {
        if (props.triples) {
            setMaxCard(props.triples.length - 1)
            setCurrentCard(0)
        }
    }, [props.triples])

    if (props.triples && !props.processed) {
        return (
            <div id='card=container'>
                {props.triples.map((object, index) =>
                    <TripleCard key={index} index={index} activity={object.Activity} risk={object.Risk}
                        mitigation={object.Mitigation} update={updateCurrentCard} getCurrentCard={getCurrentCard}
                        db={props.db} currentCard={currentCard} id={object.id} setPreviousTriple={props.setPreviousTriple}
                        previousActivity={props.previousActivity} previousRisk={props.previousRisk} />
                )}
            </div>
        )
    } else {
        if (props.triples) {
            return (
                <div id="finished">
                    <p>Fetching more triples</p> <br></br>
                    {/* <img src="https://i.pinimg.com/originals/27/69/ef/2769efc022d66105a614b022d1f55e74.gif" alt=""/> */}
                </div>
            )
        }

        return (
            <p>Nothing to show</p>
        )
    }
}

export default TripleCards
