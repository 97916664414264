import React from 'react'

function MetricTile (props) {
    return (
        <div style={{ margin: 'auto', width: 225, height: 125, backgroundColor: '#FFFFFF', border: '1px solid #E5E5E6', fontSize: 20 }} >
            <p > {props.title} </p>
            <p>
                {props.value}
            </p>
        </div>
    )
}

export default MetricTile
