function deleteSafetyBriefing (id, fetchNext, keycloak) {
    console.log('Deleting safety briefing id:', id)
    const url = `https://api.intuety.io/aurora/api/safety-briefing/file/${id}`
    const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Auth-Token': keycloak.tokenParsed?.api_token
        }
    }
    fetch(url, options).then(response => {
        if (response.status >= 400) {
            throw new Error(response.body.json())
        } else {
            console.log('successfully deleted')
            setTimeout(fetchNext, 500)
        }
    }).catch(error => {
        alert(error)
    })
}

export default deleteSafetyBriefing
