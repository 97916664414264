import React from 'react'
import { PieChart, Pie, Label } from 'recharts'

function MetricPieChart (props) {
    return (<div>
        <div style={{ width: 200, height: 30, fontSize: 20 }} >
            <p>{props.title}</p>
        </div>
        <PieChart width={200} height={225} >
            <Pie data={props.data} dataKey="count" outerRadius={85} innerRadius={55} fill="fill">
                <Label value={props.count} position="center" fontSize={30}/>
            </Pie>
        </PieChart>
    </div>)
}

export default MetricPieChart
