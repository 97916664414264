import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import PublisherInput from './PublisherInput'

function SearchOptions (props) {
    function onKeyDown (e) {
        if (e.keyCode === 13) {
            e.preventDefault()
        }
    }

    return (
        <Row>

            <Col>
                <Form>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        onChange={props.updateTitle}
                        onKeyDown={onKeyDown}
                        type="text"
                    />
                </Form>
            </Col>
            <Col>
                <Form>
                    <Form.Label>Tags</Form.Label>
                    <Form.Control
                        onChange={props.updateTags}
                        onKeyDown={onKeyDown}
                        type="text"
                    />
                </Form>
            </Col>
            <Col>
                <Form>
                    <Form.Label>Publisher</Form.Label>
                    <PublisherInput setPublisher={props.updatePublisher} publisher={props.publisher} />
                </Form>
            </Col>
            <Col>
                <Form>
                    <Form.Label>Document Type</Form.Label>
                    <Form.Control as="select" onChange={props.updateDocType}>
                        <option>N/A</option>
                        <option>Safety Briefing</option>
                        <option>Safety Alert</option>
                        <option>Company Standards</option>
                        <option>Company Policy</option>
                        <option>Toolbox Talk</option>
                    </Form.Control>
                </Form>
            </Col>
        </Row>
    )
}

export default SearchOptions
