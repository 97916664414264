import React from 'react'
import { Button } from 'react-bootstrap'

import { useKeycloak } from '@react-keycloak/web'

function EditButton (props) {
    const { keycloak } = useKeycloak()

    function handleClick () {
        updateEntry()
    }

    function buildQuery () {
        const query =
        {
            Activity: props.activity,
            Risk: props.risk,
            Mitigation: props.mitigation
        }
        return JSON.stringify(query)
    }

    function getURL () {
        if (props.db === 'Live Database') {
            return 'https://api.intuety.io/aurora/api/elasticdb/otg/update'
        } else if (props.db === 'Staging Database') {
            return 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/update'
        }
    }

    function updateEntry () {
        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token
            },
            body: buildQuery()
        }
        fetch(`${getURL()}/${props.id}`, options).then(response => {
            console.log(`Edit status code received: ${response.status}`)
        }).then(() => {
            props.setEdited(true)
        }).catch(error => {
            console.log(`Error received: ${error}`)
        })
    }

    function getText () {
        if (props.card) {
            return (' Swap ')
        } else {
            return (' Submit ')
        }
    }

    return (
        <div id="modal-button">
            <Button variant="outline-success" onClick={handleClick}> {getText()} </Button>
        </div>
    )
}

export default EditButton
