import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { getAllClients } from '../utils'

function SearchResultsTable (props) {
    const [clients, setClients] = useState([])

    useEffect(() => {
        const fetchClients = async () => {
            const res = await getAllClients()
            setClients(res)
        }

        fetchClients()
    })

    return (
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Associated Activities</th>
                    <th>Clients</th>
                </tr>
            </thead>
            <tbody>
                {props.searchResults.map((result, pos) => (
                    <tr key={pos}>
                        <td>{result._source.name}</td>
                        <td>{result._source.associated_activities.length > 0 ? result._source.associated_activities.join(', ') : 'Associated Activity list is empty'}</td>
                        <td>{result._source.client_ids && result._source.client_ids.length > 0
                            ? result._source.client_ids.map(id => {
                                const res = clients.find(client => id === client.clientId)

                                if (res) {
                                    return res.clientName
                                }

                                return ''
                            }).join(', ')
                            : 'Client ID list is empty'}</td>
                        <td style={{ border: 'none' }}>
                            <Button style={{ marginLeft: '5px' }} onClick={() => props.onShowEditModal(pos)}>Edit</Button>
                            <Button variant="danger" style={{ marginLeft: '5px' }} onClick={() => { props.onShowDeleteModal(pos) }}>Delete</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default SearchResultsTable
