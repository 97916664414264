import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

const ResultModal = (props) => {
    const [longestList, setLongestList] = useState([])

    const handleClose = () => props.setShow(false)

    useEffect(() => {
        if (props.result) {
            if (props.result.added.length > props.result.failed.length) {
                setLongestList([...props.result.added])
            } else {
                // Failed list always contains null value due to how csv is being sent
                setLongestList([...props.result.failed.slice(0, -1)])
            }
        }
    }, [props.result])

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Submit new users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <table>
                    <thead>
                        <th>Successfully Created</th>
                        <th>Failed to create</th>
                    </thead>
                    <tbody>
                        {longestList.map((__, pos) => (
                            <tr key={pos}>
                                <td>{props.result.added[pos]}</td>
                                <td>{props.result.failed[pos]}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default ResultModal
