import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap'
import fetchPublisherList from './FetchPublisherList'
import { useKeycloak } from '@react-keycloak/web'

function PublisherInput (props) {
    const [publisherList, setPublisherList] = useState([])
    const [currentFocus, setCurrentFocus] = useState(-1)
    const [publisherSuggestions, setPublisherSuggestions] = useState([])
    const { keycloak } = useKeycloak()

    function onPublisherChange (e) {
        props.setPublisher(e.target.value)

        document.getElementById('Publisher-Autocomplete').innerHTML = ''
        setCurrentFocus(-1)

        const suggestions = []

        for (const publisher of publisherList) {
            if (publisher.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())) {
                suggestions.push(publisher)
            }
        }

        setPublisherSuggestions(suggestions)

        for (const index in suggestions) {
            const suggestionDiv = document.createElement('DIV')
            suggestionDiv.setAttribute('id', `Suggestion-${index}`)
            suggestionDiv.setAttribute('class', 'Suggestion')
            suggestionDiv.addEventListener('click', () => { props.setPublisher(suggestions[index]) })
            suggestionDiv.innerHTML = suggestions[index]
            document.getElementById('Publisher-Autocomplete').appendChild(suggestionDiv)
        }
    }

    function onPublisherKeyDown (event) {
        const oldID = `Suggestion-${currentFocus}`

        if (event.keyCode === 40 && currentFocus < publisherSuggestions.length - 1) {
            // Down key hit
            event.preventDefault()
            const newID = `Suggestion-${currentFocus + 1}`
            changeFocus(oldID, newID)
            setCurrentFocus(currentFocus + 1)
        }

        if (event.keyCode === 38 && currentFocus > -1) {
            // Up key hit
            event.preventDefault()
            const newID = `Suggestion-${currentFocus - 1}`
            changeFocus(oldID, newID)
            setCurrentFocus(currentFocus - 1)
        } else if (event.keyCode === 13) {
            // Enter key hit
            event.preventDefault()

            if (currentFocus > -1) {
                props.setPublisher(document.getElementById(oldID).innerHTML)
                document.getElementById('Publisher-Autocomplete').innerHTML = ''
                setPublisherSuggestions([])
            }
        }
    }

    function changeFocus (oldID, newID) {
        if (document.getElementById(oldID)) {
            document.getElementById(oldID).setAttribute('class', 'Suggestion')
        }

        if (document.getElementById(newID)) {
            document.getElementById(newID).setAttribute('class', 'Focus')
        }
    }

    function onBlur () {
        setTimeout(() => {
            if (document.getElementById('Publisher-Autocomplete')) {
                document.getElementById('Publisher-Autocomplete').innerHTML = ''
            }

            setPublisherSuggestions([])
        },
        200)
    }

    useEffect(() => {
        async function fetchList () {
            const list = await fetchPublisherList(keycloak)
            setPublisherList(list)
        }

        fetchList()
    }, [])

    return (
        <>
            <Form.Control
                placeholder="Type here"
                value={props.publisher}
                onChange={onPublisherChange}
                onKeyDown={onPublisherKeyDown}
                onBlur={onBlur}
            />
            <div
                id="Publisher-Autocomplete"
                className={publisherSuggestions.length > 0 ? 'Publisher-Autocomplete' : null}
            />
        </>
    )
}

export default PublisherInput
