import { useKeycloak } from '@react-keycloak/web'
import React from 'react'
import { Route } from 'react-router-dom'

export default function PrivateRoute ({ comp: Component, ...rest }) {
    const { keycloak } = useKeycloak()

    const isAuthenticated = () => {
        return keycloak.authenticated
    }

    return (
        <Route
            {...rest}
            render={(props) =>
                isAuthenticated()
                    ? (
                        <Component {...props} />
                    )
                    : keycloak.login()
            }
        />
    )
}
