function updateSafetyBriefing (text, tags, doctype, publisher, isPrivate, formConfig, id, fetchNext, keycloak) {
    console.log('Updating Safety Briefing:', id)

    const body = {}

    if (formConfig[0]) {
        body.Text = text
    }

    if (formConfig[1]) {
        body.Tags = tags
    }

    if (formConfig[2]) {
        body.DocumentType = doctype
    }

    if (formConfig[3]) {
        body.Publisher = publisher
    }

    if (formConfig[4]) {
        body.Private = isPrivate
    }

    console.log(body)

    const options = {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Auth-Token': keycloak.tokenParsed?.api_token
        },
        body: JSON.stringify(body)
    }

    fetch(`https://api.intuety.io/aurora/api/safety-briefing/file/${id}`, options).then(response => {
        console.log(`response code recieved ${response.status}`)

        if (response.status >= 400) {
            console.log(response)
            throw new Error(response.body.json())
        } else {
            fetchNext()
        }
    }).catch(err => {
        alert('Problem updating safety briefing information')
        console.log(err)
    })
}

export default updateSafetyBriefing
