import React, { useState } from 'react'
import DatabaseSelection from '../Triples/DatabaseSelection'
import { Container, Row, Button, Col } from 'react-bootstrap'
import Searchbar from './Searchbar'
import searchProhibitedEquipment from './SearchProhibitedEquipment'
import { useKeycloak } from '@react-keycloak/web'
import SearchResultsTable from './SearchResultsTable'
import AddModal from './AddProhibitedEquipmentModal'
import EditModal from './EditProhibitedEquipmentModal'
import DeleteModal from './DeleteProhibitedEquipmentModal'
import { ViewAllClientsButton } from '../utils'

function ProhibitedEquipment () {
    const [db, setDB] = useState('Live Database')
    const [name, setName] = useState('')
    const [activity, setActivity] = useState('')
    const [clientID, setClientID] = useState('')
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [editTriple, setEditTriple] = useState(null)
    const [deleteTriple, setDeleteTriple] = useState(null)
    const [searchResults, setSearchResults] = useState(null)
    const [showClients, setShowClients] = useState(null)

    const { keycloak } = useKeycloak()

    const onSearch = () => {
        searchProhibitedEquipment(name, activity, clientID, db, keycloak, setSearchResults)
    }

    const onShowEditModal = (pos) => {
        setEditTriple(searchResults[pos])
        setShowEditModal(true)
    }

    const onShowDeleteModal = (pos) => {
        setDeleteTriple(searchResults[pos])
        setShowDeleteModal(true)
    }

    return (
        <Container>
            <AddModal setShow={setShowAddModal} show={showAddModal} db={db} updateSearch={onSearch}/>
            <EditModal setShow={setShowEditModal} show={showEditModal} item={editTriple} db={db} updateSearch={onSearch}/>
            <DeleteModal setShow={setShowDeleteModal} show={showDeleteModal} item={deleteTriple} db={db} updateSearch={onSearch}/>
            <Row>
                <Col><h1 id="title">Prohibited Equipment</h1></Col>
                <Col><DatabaseSelection db={db} setDB={setDB} /></Col>
            </Row>
            <br></br>
            <Row>
                <Col>
                    <Searchbar placeholder="Name" newSearch={onSearch} setValue={setName} />
                </Col>
                <Col>
                    <Searchbar placeholder="Activity" newSearch={onSearch} setValue={setActivity} />
                </Col>
                <Col>
                    <Searchbar placeholder="Client ID" newSearch={onSearch} setValue={setClientID} />
                </Col>
                <Col>
                    <Button onClick={onSearch}>Search</Button>
                </Col>
            </Row>
            <br></br>
            <Row>
                <Button onClick={() => { setShowAddModal(true) }}>Add Prohibited Equipment</Button>
                <ViewAllClientsButton show={showClients} setShow={setShowClients} />
            </Row>
            <br></br>
            <Row>
                {searchResults ? <SearchResultsTable searchResults={searchResults} onShowEditModal={onShowEditModal} onShowDeleteModal={onShowDeleteModal}/> : null}
            </Row>
        </Container>
    )
}

export default ProhibitedEquipment
