
export const getClient = async (token, id) => {
    if (id) {
        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                token: token
            }
        }

        const url = `https://app.intuety.io/api/v2/client/${id}`

        const res = await fetch(url, options).then(response => {
            return response.json()
        })

        return res.data
    } else {
        return {
            clientId: undefined,
            clientName: 'Testing',
            created: null,
            modified: null,
            status: 1,
            delete: 0
        }
    }
}
