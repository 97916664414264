import React, { useEffect, useState } from 'react'
import getNextUntagged from './GetNextUntagged'
import updateSafetyBriefing from './UpdateSafetyBriefing'
import { Container, Row, Dropdown, Form } from 'react-bootstrap'
import CheckDeleteModal from './CheckDeleteModal'
import deleteSafetyBriefing from './DeleteSafetyBriefing'

import { useKeycloak } from '@react-keycloak/web'

import './MissingFields.css'
import FieldsInput from './FieldsInput'

function MissingFields () {
    const { keycloak } = useKeycloak()
    const [text, setText] = useState(undefined)
    const [tags, setTags] = useState(undefined)
    const [doctype, setDoctype] = useState(undefined)
    const [publisher, setPublisher] = useState(undefined)
    const [isPrivate, setIsPrivate] = useState(undefined)
    const [briefing, setBriefing] = useState(undefined)
    const configFields = ['Text', 'Tags', 'Doctype', 'Publisher', 'Private']
    const [formConfig, setFormConfig] = useState([false, false, false, true, true])
    const [file, setFile] = useState(undefined)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [missingField, setMissingField] = useState('Publisher')

    function onPublisherChange (value) {
        setPublisher(value)
    }

    function onPrivateChange (e) {
        if (e.target.value === 'private') {
            setIsPrivate(true)
        } else {
            setIsPrivate(false)
        }
    }

    function onOptionsChange (num) {
        const temp = [null, null, null, null, null]

        for (let i = 0; i < formConfig.length; i++) {
            if (i === num) {
                if (formConfig[i]) {
                    temp[i] = false
                } else {
                    temp[i] = true
                }
            } else {
                if (formConfig[i]) {
                    temp[i] = true
                } else {
                    temp[i] = false
                }
            }
        }

        setFormConfig(temp)
    }

    function onSubmit () {
        updateSafetyBriefing(text, tags, doctype, publisher, isPrivate, formConfig, briefing._id, fetchNext, keycloak)
    }

    function fetchNext () {
        setBriefing(null)
        setFile(null)
        setTimeout(getNextUntagged, 500, missingField, setBriefing, setFile, briefing._id, keycloak)
    }

    function deleteBriefing () {
        deleteSafetyBriefing(briefing._id, fetchNext, keycloak)
    }

    useEffect(() => {
        getNextUntagged(missingField, setBriefing, setFile, false, keycloak)
    }, [])

    useEffect(() => {
        setBriefing(null)
        setFile(null)
        getNextUntagged(missingField, setBriefing, setFile, false, keycloak)
    }, [missingField])

    useEffect(() => {
        if (briefing) {
            if (briefing._source.Text) {
                setText(briefing._source.Text)
            }

            if (briefing._source.Tags) {
                setTags(briefing._source.Tags)
            }

            if (briefing._source.DocumentType) {
                setDoctype(briefing._source.DocumentType)
            }

            if (briefing._source.Publisher) {
                setPublisher(briefing._source.Publisher)
            }

            if (typeof briefing._source.Private === 'boolean') {
                setIsPrivate(briefing._source.Private)
            }
        } else {
            setText(undefined)
            setTags(undefined)
            setDoctype(undefined)
            setPublisher(undefined)
            setIsPrivate(undefined)
        }
    }, [briefing])

    function displayFile () {
        if (file === 'No file found') {
            return <p>No file found</p>
        } else if (file) {
            return (
                <embed src={file} className="Missing-PDF" />
            )
        } else {
            return <p>Fetching file</p>
        }
    }

    return (
        <>
            <CheckDeleteModal show={showDeleteModal} setShow={setShowDeleteModal} deleteBriefing={deleteBriefing} />
            <Container>
                <Row>
                    <div className="Header">
                        <h1>Missing Fields</h1>
                        <Dropdown className="Field-Select">
                            <Dropdown.Toggle>{missingField}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => setMissingField('Tags')}> Tags</Dropdown.Item>
                                <Dropdown.Item onClick={() => setMissingField('Text')}>Text</Dropdown.Item>
                                <Dropdown.Item onClick={() => setMissingField('DocumentType')}>DocumentType</Dropdown.Item>
                                <Dropdown.Item onClick={() => setMissingField('Publisher')}>Publisher</Dropdown.Item>
                                <Dropdown.Item onClick={() => setMissingField('Private')}>Private</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Row>
                <Row>
                    <Form>
                        <div className="Missing-Input-Options">
                            {formConfig.map((isSelected, number) => {
                                return (<Form.Check key={number} index={number} type="checkbox"
                                    label={configFields[number]}
                                    checked={isSelected}
                                    onChange={() => { onOptionsChange(number) }}
                                    style={{ margin: '5px' }}
                                />)
                            })}
                        </div>
                    </Form>
                </Row>
                <Row>
                    <div className="Missing-Holder">
                        <div className="Missing-Column">
                            {displayFile()}
                        </div>
                        <div className="Missing-Column">
                            <FieldsInput
                                briefing={briefing}
                                formConfig={formConfig}
                                text={text}
                                tags={tags}
                                doctype={doctype}
                                publisher={publisher}
                                isPrivate={isPrivate}
                                showDeleteModal={showDeleteModal}
                                setShowDeleteModal={setShowDeleteModal}
                                setText={setText}
                                setTags={setTags}
                                setDoctype={setDoctype}
                                onPublisherChange={onPublisherChange}
                                onPrivateChange={onPrivateChange}
                                onSubmit={onSubmit}
                            />
                        </div>
                    </div>
                </Row>
            </Container>
        </>
    )
}

export default MissingFields
