import Keycloak from 'keycloak-js'

// keycloak init options
const initOptions = {
    url: 'https://internal-tools.intuety.io/auth',
    realm: 'Intuety-Internal-Tools',
    clientId: 'intuety-internal-tools'
}

const keycloak = new Keycloak(initOptions)
export default keycloak
