import React from 'react'

import StatusChecker from './StatusChecker'

function Status () {
    return (
        <StatusChecker />
    )
}

export default Status
