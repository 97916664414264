export const sortToday = (jobs, failedData) => {
    const data = []

    for (const pos in jobs) {
        let time = pos

        if (time < 0) {
            time = 24 + time
        }

        if (time < 10) {
            time = `0${time}`
        }

        data.push({
            name: `${time}:00`,
            count: jobs[pos].length,
            failedcount: failedData[pos].length,
            data: jobs[pos].concat(failedData[pos])
        })
    }

    return data
}

export const sortDay = (jobs, failedData) => {
    const data = []

    for (const pos in jobs) {
        let time = pos

        if (time < 0) {
            time = 24 + time
        }

        if (time < 10) {
            time = `0${time}`
        }

        data.push({
            name: `${time}:00`,
            count: jobs[pos].length,
            failedcount: failedData[pos].length,
            data: jobs[pos].concat(failedData[pos])
        })
    }

    return data
}

const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const sortWeek = (jobs, failedData) => {
    const data = [0, 1, 2, 3, 4, 5, 6]
    const date = new Date()

    for (const pos in jobs) {
        let newPos = parseInt(pos) - date.getDay()

        if (newPos < 0) {
            newPos = 7 + newPos
        }

        data[newPos] = {
            name: weekDays[pos],
            count: jobs[pos].length,
            failedcount: failedData[pos].length,
            data: jobs[pos].concat(failedData[pos])
        }
    }

    return data
}

export const sortMonth = (jobs, failedData, month = null) => {
    if (jobs.length > 0 && failedData.length > 0) {
        const data = []
        const date = new Date()

        for (const pos in jobs) {
            const currentMonth = date.getMonth()
            const currentDay = date.getDate()

            const day = currentDay - (currentDay - pos) + 1

            if (!month) {
                pos >= currentDay ? month = currentMonth + 1 : month = currentMonth + 2
            }

            data.push({
                name: `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}`,
                count: jobs[pos].length,
                failedcount: failedData[pos].length,
                data: jobs[pos].concat(failedData[pos])
            })
        }

        return data
    }
}

export const sortLast30 = (jobs, failedData) => {
    const data = []
    const now = new Date()
    const mon = new Date()
    const thirty = [4, 6, 9, 11]
    const feb = [2]
    let date = 31
    let minus = 0

    for (const pos in jobs) {
        let month = mon.getMonth() + 1

        if (month === thirty) {
            minus = 30 - pos
            date = now.getDate() - minus
        }

        if (date === 0) {
            month = mon.getMonth()

            date = 31
        }

        if (date < 0) {
            month = mon.getMonth()
            date = date + 31
        }

        if (month === feb) {
            minus = 28 - pos
            date = now.getDate() - minus
        }

        if (date === 0) {
            month = mon.getMonth()

            date = 31
        }

        if (date < 0) {
            month = mon.getMonth()
            date = date + 31
        } else {
            minus = 30 - pos
            date = now.getDate() - minus
        }

        if (date === 0) {
            month = mon.getMonth()

            date = 31
        }

        if (date < 0) {
            month = mon.getMonth()
            date = date + 31
        }

        data.push({
            name: `${date}/${month}`,
            count: jobs[pos].length,
            failedcount: failedData[pos].length,
            data: jobs[pos].concat(failedData[pos])
        })
    }

    return data
}

const months = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December']

export const sortYear = (jobs, failedData) => {
    if (jobs.length > 0 && failedData.length > 0) {
        const data = []

        for (const pos in jobs) {
            let month = pos

            if (month < 0) {
                month = month + 12
            }

            data.push({
                name: months[month],
                count: jobs[pos].length,
                failedcount: failedData[pos].length,
                data: jobs[pos].concat(failedData[pos])
            })
        }

        return data
    }
}
