import { useKeycloak } from '@react-keycloak/web'
import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import DatabaseSelection from '../Triples/DatabaseSelection'
import TriplesSearchbar from '../Triples/SearchTriples/TriplesSearchbar'
import ComparisonCheck from './ComparisonCheck'
import ComparisonResults from './ComparisonResults'
import LookupResults from './LookupResults'
import SemanticCheck from './SemanticCheck'

const Checkbox = ({ value, setValue, label }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p>{label}</p>
            <input type="checkbox" checked={value === label} onChange={() => { setValue(label) }} style={{ margin: '5px' }} />
        </div>
    )
}

function SemanticLookup () {
    const { keycloak } = useKeycloak()
    const [db, setDB] = useState('Live Database')
    const [activity, setActivity] = useState('')
    const [risk, setRisk] = useState('')
    const [mitigation, setMitigation] = useState('')
    const [sentence1, setSentence1] = useState('')
    const [sentence2, setSentence2] = useState('')
    const [algorithm, setAlgorithm] = useState('cosine')
    const [isComparison, setIsComparison] = useState(false)
    const [lookupResults, setLookupResults] = useState(null)
    const [comparisonResults, setComparisonResults] = useState(null)

    const enterHit = () => {
        if (isComparison) {
            newComparison()
        } else {
            newLookup()
        }
    }

    const newLookup = async () => {
        const result = await SemanticCheck(activity, risk, mitigation, db, keycloak)
        setLookupResults(result)
    }

    const newComparison = async () => {
        const result = await ComparisonCheck(sentence1, sentence2, algorithm, db, keycloak)
        setComparisonResults(result)
    }

    return (
        <Container>
            <Row>
                <Col><h1 id="title"> Semantic Lookup </h1></Col>
                <Col><DatabaseSelection db={db} setDB={setDB} /></Col>
            </Row>
            {isComparison
                ? <>
                    <Row>
                        <Col><TriplesSearchbar placeholder="Sentence 1" setValue={setSentence1} newSearch={enterHit} /></Col>
                        <Col><TriplesSearchbar placeholder="Sentence 2" setValue={setSentence2} newSearch={enterHit} /></Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>Algorithm:</p>
                        </Col>
                        <Col>
                            <Checkbox value={algorithm} setValue={setAlgorithm} label="cosine" />
                        </Col>
                        <Col>
                            <Checkbox value={algorithm} setValue={setAlgorithm} label="jaccard" />
                        </Col>
                        <Col>
                            <Checkbox value={algorithm} setValue={setAlgorithm} label="transformer" />
                        </Col>
                    </Row>
                </>
                : <>
                    <Row>
                        <Col><TriplesSearchbar placeholder="Activity" setValue={setActivity} newSearch={enterHit} /></Col>
                        <Col><TriplesSearchbar placeholder="Risk" setValue={setRisk} newSearch={enterHit} /></Col>
                        <Col><TriplesSearchbar placeholder="Mitigation" setValue={setMitigation} newSearch={enterHit} /></Col>
                    </Row>
                </>
            }
            <Row style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', display: 'flex', flexDirection: 'row', right: '0px' }}>
                    <p>Compare to another triple </p>
                    <input type="checkbox" checked={isComparison} onChange={() => { setIsComparison(!isComparison) }} style={{ margin: '5px' }} />
                </div>
            </Row>
            <Row>
                <Button onClick={enterHit}>{isComparison ? 'Compare' : 'Lookup'}</Button>
            </Row>

            {isComparison
                ? <ComparisonResults result={comparisonResults} />
                : <LookupResults results={lookupResults} />}
        </Container>
    )
}

export default SemanticLookup
