import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Input,
    Select,
    MenuItem,
    Container
    // Box,
    // FormControl,
    // InputLabel,
} from "@mui/material"
import PaginationTableFooter from "../Components/PaginationTableFooter"
import { useKeycloak } from "@react-keycloak/web"
import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import { getAllClients, getAllUsers } from "../utils"

const Header = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin: 0px 20px 0px 20px;
`

function Users() {

    const [users, setUsers] = useState([])
    const [clients, setClients] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [filter, setFilter] = useState("")
    const [clientFilter, setClientFilter] = useState("AllClients")
    const [filteredUsers, setFilteredUsers] = useState([])
    // const [primary, setPrimary] = React.useState('')

    const { keycloak } = useKeycloak()

    useEffect(() => {
        getUsers()
        getClients()
    }, [])

    useEffect(() => {
        var filteredUsers = users.filter((user) => {
            return user.fullName.toLowerCase().includes(filter.toLowerCase())
        })

        if (clientFilter !== "AllClients") {
            filteredUsers = filteredUsers.filter((user) => {
                return user.clientName.toLowerCase().includes(clientFilter.toLowerCase())
            })
        }

        setFilteredUsers(filteredUsers)


    }, [filter, clientFilter, users])

    const getUsers = async () => {
        const response = await getAllUsers(keycloak.tokenParsed?.internal_services_token)
        setUsers(response)
        setFilteredUsers(response)
    }

    const getClients = async () => {
        let res = await getAllClients()
        setClients(res)
    }

    const handleChangePage = (newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (rows) => {
        setRowsPerPage(rows)
    }

    const handleFilterInput = (event) => {
        setFilter(event.target.value)
    }

    const handleClientFilterInput = (event) => {
        setClientFilter(event.target.value)
    }

    // const handleChange = (event) => {
    //     setPrimary(event.target.value)
    // }


    return (
        <>
            <Container>
                <Header maxWidth='md'>
                    <h1> Users</h1>
                    <Select
                        label="Select Client"
                        value={clientFilter}
                        initialValue="AllClients"
                        onChange={handleClientFilterInput}
                    >
                        <MenuItem value="AllClients">All Clients</MenuItem>
                        {clients.map((client) => (<MenuItem key={client.clientId} value={client.clientName}>{client.clientName}</MenuItem>))}
                    </Select>

                    {/* <Box sx={{ minWidth: 250 }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Primary client</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={primary}
                            label='primary client'
                            onChange={handleChange}
                        >
                            <MenuItem>{'yo'}</MenuItem>
                        </Select>
                    </FormControl>
                </Box> */}

                    <Input placeholder='Filter By User' initialValue={filter} label='filter' onChange={handleFilterInput} />

                </Header>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> # </TableCell>
                                <TableCell> Name </TableCell>
                                <TableCell> RoleID </TableCell>
                                <TableCell> Email </TableCell>
                                <TableCell> Primary Client </TableCell>
                                <TableCell> Active </TableCell>
                                <TableCell> Enabled </TableCell>
                                <TableCell> Created </TableCell>
                                <TableCell> Modified </TableCell>
                                <TableCell> Deleted </TableCell>
                                <TableCell> Email notifs </TableCell>
                                <TableCell> BetaOptin </TableCell>


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredUsers
                            ).map((user) => {
                                return (
                                    <TableRow key={user.userId}>
                                        <TableCell> {user.userId} </TableCell>
                                        <TableCell> {user.fullName} </TableCell>
                                        <TableCell> {user.roleID} </TableCell>
                                        <TableCell> {user.email} </TableCell>
                                        <TableCell> {user.clientName}</TableCell>
                                        <TableCell> {user.active} </TableCell>
                                        <TableCell> {user.enabled} </TableCell>
                                        <TableCell> {user.created} </TableCell>
                                        <TableCell> {user.modified} </TableCell>
                                        <TableCell> {user.deleted} </TableCell>
                                        <TableCell> {user.enableEmailNotifications} </TableCell>
                                        <TableCell> {user.betaOptin} </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <PaginationTableFooter
                            count={users.length}
                            changeTablePage={handleChangePage}
                            changeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default Users
