import React from 'react'

// Returns a row for each log in the statusLog with releveant info

function StatusLogRow (props) {
    // Returns the written-word status of a log
    function getStatus (status) {
        if (status === 0) {
            return 'Error Occured'
        } else if (status === 1) {
            return 'In Progress'
        } else if (status === 2) {
            return 'Completed'
        }
    }

    return (
        <tr>
            <td>{props.status._source.Job_ID}</td>
            <td>{getStatus(props.status._source.Status)}</td>
            <td>{props.status._source.Remarks}</td>
            <td>{(new Date(props.status._source.Timestamp)).toLocaleString()}</td>
        </tr>
    )
}

export default StatusLogRow
