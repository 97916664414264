import React, { useState } from 'react'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap'

import DatabaseSelection from '../DatabaseSelection'
import AddTripleInput from './AddTripleInput'
import SendTriple from './SendTriple'

function AddTriples () {
    const [db, setDB] = useState('Live Database')
    const [activity, setActivity] = useState('')
    const [risk, setRisk] = useState('')
    const [mitigation, setMitigation] = useState('')
    const [showModal, setShowModal] = useState(false)

    function getValidTriple () {
        if (activity && risk && mitigation) {
            return true
        } else {
            return false
        }
    }

    function handleClose () {
        setShowModal(false)
    }

    return (
        <Container>
            <Row>
                <Col><h1 id="title"> Add Triples </h1></Col>
                <Col><DatabaseSelection db={db} setDB={setDB}/></Col>
            </Row>
            <Row>
                <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Triple Sent</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>The following triple was successfully sent</p>
                        <p><b>Database:</b> {db}</p>
                        <p><b>Activity:</b> {activity}</p>
                        <p><b>Risk:</b> {risk}</p>
                        <p><b>Mitigation:</b> {mitigation}</p>
                        <br></br>
                        <Button onClick={handleClose}> Close </Button>
                    </Modal.Body>
                </Modal>
            </Row>
            <Row>
                <Col><AddTripleInput title="Activity" value={activity} setValue={setActivity}/></Col>
                <Col><AddTripleInput title="Risk" value={risk} setValue={setRisk}/></Col>
                <Col><AddTripleInput title="Mitigation" value={mitigation} setValue={setMitigation}/></Col>
            </Row>
            <Row>
                <SendTriple activity={activity} risk={risk} mitigation={mitigation} db={db}
                    getValidTriple={getValidTriple} setShowModal={setShowModal}/>
            </Row>
        </Container>
    )
}

export default AddTriples
