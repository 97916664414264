import React from 'react'
import { Button } from 'react-bootstrap'
import { useKeycloak } from '@react-keycloak/web'

function DeleteButton (props) {
    const { keycloak } = useKeycloak()

    function handleClick () {
        deleteEntry()
    }

    function getURL () {
        if (props.db === 'Live Database') {
            return 'https://api.intuety.io/aurora/api/elasticdb/otg/delete'
        } else if (props.db === 'Staging Database') {
            return 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/delete'
        }
    }

    function deleteEntry () {
        const options = {
            method: 'DELETE',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token
            }
        }

        fetch(`${getURL()}/${props.id}`, options).then(response => {
            console.log(`Delete status code received: ${response.status}`)
        }).then(() => {
            props.setDeleted(true)
        }).catch(error => {
            console.log(`Error received: ${error}`)
        })
    }

    return (
        <div id='modal-button'>
            <Button variant="outline-danger" onClick={handleClick}> Delete </Button>
        </div>
    )
}

export default DeleteButton
