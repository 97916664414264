import React from 'react'
import { Form, Button } from 'react-bootstrap'
import styled from 'styled-components'

const Wrapper = styled.div`
    border: 2px black solid;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    position: relative;
`

const InputHolder = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
`

const Label = styled.p`
    margin-right: 1em;
    max-width: 52%;
    word-wrap: break-word;
`

const CheckboxOn = styled.div`
    width: 40px;
    height: 40px;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }
`

const CheckboxOff = styled.div`
    width: 40px;
    height: 40px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }
`

const FieldHolder = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const RemoveButton = styled.div`
    width: 63px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    border: 2px red solid;

    &:hover {
        cursor: pointer;
    }
`

function TextField ({ objectKey, value, path, environment, update, remove }) {
    const onUpdate = (e) => {
        if (e.target.value.length === 1 && parseFloat(e.target.value)) {
            update(path, parseFloat(e.target.value), environment)
        } else {
            update(path, e.target.value, environment)
        }
    }

    const onRemove = () => {
        remove(path, environment)
    }

    const updateBool = () => {
        if (value === 'off') {
            update(path, 'on', environment)
        } else {
            update(path, 'off', environment)
        }
    }

    if (value === 'off' || value === 'on') {
        return (
            <Wrapper>
                <InputHolder>
                    <p>{objectKey}</p>
                    {value === 'on' ? <CheckboxOn onClick={updateBool}>On</CheckboxOn> : <CheckboxOff onClick={updateBool}>Off</CheckboxOff>}
                    <RemoveButton onClick={onRemove}>Remove</RemoveButton>
                </InputHolder>
            </Wrapper>
        )
    } else {
        return (
            <Wrapper>
                <Form>
                    <InputHolder>
                        <Label>{objectKey}:</Label>
                        <FieldHolder>
                            <Form.Control type={'string'} value={value} onChange={onUpdate} />
                            {value === '' ? <Button variant="danger" onClick={onRemove} style={{ height: '2.5em', marginLeft: '5px' }}>Remove</Button> : null}
                        </FieldHolder>
                    </InputHolder>
                </Form>
            </Wrapper>
        )
    }
}

export default TextField
