import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

function NewFieldModal ({ show, environment, setShow, addNewField, inArray }) {
    const [name, setName] = useState('')
    const [type, setType] = useState('string')

    const handleClose = () => { setShow(false) }

    const addField = () => {
        addNewField(name, type, environment)
        handleClose()
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Field</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {inArray
                        ? null
                        : <Form.Group>
                            <Form.Label>Field Name <i>(ALL CAPS RECOMMENDED)</i></Form.Label>
                            <Form.Control onChange={(e) => { setName(e.target.value) }} placeholder="Type Here..." />
                        </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label>Type</Form.Label>
                        <Form.Check type="radio" name="type" label="String" onChange={() => { setType('string') }} checked={type === 'string'} />
                        <Form.Check type="radio" name="type" label="Number" onChange={() => { setType('number') }} />
                        {inArray ? null : <Form.Check type="radio" name="type" label="Boolean" onChange={() => { setType('boolean') }} />}
                        <Form.Check type="radio" name="type" label="Object" onChange={() => { setType('object') }} />
                    </Form.Group>
                    <Button onClick={addField}>Add Field</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default NewFieldModal
