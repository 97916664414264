import React, { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import styled from 'styled-components'
import NewFieldModal from './NewFieldModal'
import ObjectField from './ObjectField'

const FieldHolder = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

function ArrayField ({ array, path, environment, update }) {
    const [showModal, setShowModal] = useState(false)

    const addItem = (__, type) => {
        const tmp = array

        if (type === 'string') {
            tmp.push('')
        } else if (type === 'object') {
            tmp.push({})
        } else if (type === 'boolean') {
            tmp.push(false)
        } else if (type === Number) {
            tmp.push(0)
        }

        update(path, tmp, environment)
    }

    const updateArray = (value, pos) => {
        const tmp = array
        tmp[pos] = value
        update(path, tmp, environment)
    }

    const removeItem = (pos) => {
        const tmp = array
        tmp.splice(pos, 1)
        update(path, tmp, environment)
    }

    return (
        <>
            <Form>
                {array.map((item, pos) => {
                    if (typeof item === 'string') {
                        return (
                            <FieldHolder key={pos}>
                                <Form.Control
                                    value={item}
                                    style={{ marginTop: '0.5em' }}
                                    onChange={(e) => { updateArray(e.target.value, pos) }}
                                />
                                {item === '' ? <Button variant="danger" onClick={() => { removeItem(pos) }} style={{ height: '2.5em', marginLeft: '5px' }}>Remove</Button> : null}
                            </FieldHolder>
                        )
                    } else if (typeof item === 'number') {
                        return (
                            <FieldHolder key={pos}>
                                <Form.Control
                                    value={item}
                                    style={{ marginTop: '0.5em' }}
                                    onChange={(e) => {
                                        typeof e.target.value === 'number'
                                            ? updateArray(parseInt(e.target.value), pos)
                                            : updateArray(e.target.value, pos)
                                    }}
                                />
                                {item === '' ? <Button variant="danger" onClick={() => { removeItem(pos) }} style={{ height: '2.5em', marginLeft: '5px' }}>Remove</Button> : null}
                            </FieldHolder>
                        )
                    } else if (typeof item === 'object') {
                        return <ObjectField
                            key={pos}
                            object={item}
                            path={path}
                            update={updateArray}
                            removeObject={removeItem}
                            environment={environment}
                        />
                    } else {
                        return <p key={pos}>Unknown type</p>
                    }
                })}
            </Form>
            <Button onClick={() => setShowModal(true)} style={{ marginTop: '1em' }}>Add</Button>
            <NewFieldModal
                show={showModal}
                setShow={setShowModal}
                environment={environment}
                addNewField={addItem}
                inArray={true}
            />
        </>
    )
}

export default ArrayField
