const getUrl = (db) => {
    if (db === 'Live Database') {
        return 'https://api.intuety.io/aurora/api/elasticdb/otg/check-triple'
    }

    return 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/check-triple'
}

export default async function SemanticCheck (Activity, Risk, Mitigation, db, keycloak) {
    const query = { filter_type: 'transformer' }

    if (Activity) {
        query.Activity = Activity
    }

    if (Risk) {
        query.Risk = Risk
    }

    if (Mitigation) {
        query.Mitigation = Mitigation
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Auth-Token': keycloak.tokenParsed?.api_token
        },
        body: JSON.stringify(query)
    }

    const result = await fetch(getUrl(db), options).then(data => {
        return data.json()
    }).then(res => {
        console.log(res)
        return res
    })

    return result
}
