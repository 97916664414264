import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DatabaseSelection from '../Triples/DatabaseSelection'
import styled from 'styled-components'
import SubmitModal from './SubmitModal'
import ResultModal from './ResultModal'

const Password = styled.p`
    color: black;
    background-color: black;

    &:hover {
        background-color: white;
    }
`

const UserCreation = () => {
    const [db, setDb] = useState('Live Database')
    const [users, setUsers] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showResultModal, setShowResultModal] = useState(false)
    const [result, setResult] = useState(null)

    const clearFileInput = () => {
        const oldInput = document.getElementById('csv-upload')
        const newInput = document.createElement('input')
        newInput.type = 'file'
        newInput.id = oldInput.id
        newInput.name = oldInput.name
        oldInput.parentNode.replaceChild(newInput, oldInput)
    }

    const showResult = (res) => {
        setResult(res)
        setShowResultModal(true)
    }

    const generatePassword = () => {
        const pwdChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!£$%#@&*'
        const pwdLen = 16
        return Array(pwdLen).fill(pwdChars).map(function (x) { return x[Math.floor(Math.random() * x.length)] }).join('')
    }

    const onUpload = (e) => {
        const file = e.target.files[0]

        if (file.name.split('.').pop() === 'csv') {
            const reader = new FileReader()

            reader.addEventListener('load', (e) => {
                const lines = e.target.result.split('\n')
                const result = []

                for (const line of lines) {
                    if (line.includes('"')) {
                        alert(`The following line contains a " character which isn't allowed when uplaoding on this page: \n ${line}`)
                        clearFileInput()
                        return
                    }

                    const contents = line.split(',')

                    if (contents.length === 1) {
                        continue
                    } else if (contents.length !== 5) {
                        alert(`The following line is being parsed as an incorrect length: \n ${line}`)
                        clearFileInput()
                        return
                    } else {
                        if (contents[3] === '') {
                            contents[3] = generatePassword()
                        }

                        result.push(contents)
                    }
                }

                // check if headers are in the csv
                if (result[0][0].toLocaleLowerCase().includes('client') && result[0][1].toLocaleLowerCase().includes('email')) {
                    result.shift()
                }

                if (result.length > 0) {
                    setUsers([...result])
                } else {
                    alert('No users found in the uploaded file')
                }
            })

            reader.readAsText(file)
        } else {
            alert('File must be a csv')
            clearFileInput()
        }
    }

    return (
        <Container>
            <SubmitModal show={showModal} setShow={setShowModal} users={users} showResult={showResult} db={db}/>
            <ResultModal show={showResultModal} setShow={setShowResultModal} result={result} />
            <Row>
                <Col><h1 id="title">User Creation</h1></Col>
                <Col><DatabaseSelection db={db} setDB={setDb} /></Col>
            </Row>
            <Row>
                <Col>
                    <input type="file" name="file" accept=".csv" id="csv-upload" onChange={onUpload} />
                </Col>
                <Col><Link to="/UserCreationTemplate.csv" target="_blank" download><Button variant="success">Download Template</Button></Link></Col>
            </Row>
            {users.length > 0
                ? <>
                    <Row>
                        <Button onClick={() => setShowModal(true)}>Submit</Button>
                    </Row>
                    <Row>
                        <table>
                            <thead>
                                <th>Client</th>
                                <th>Email</th>
                                <th>Full Name</th>
                                <th>Password</th>
                                <th>Role Id</th>
                            </thead>
                            <tbody>
                                {users.map((user, index) => (
                                    <tr key={index}>
                                        <td>{user[0]}</td>
                                        <td>{user[1]}</td>
                                        <td>{user[2]}</td>
                                        <td><Password>{user[3]}</Password></td>
                                        <td>{user[4]}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Row>
                </>

                : null}
        </Container>
    )
}

export default UserCreation
