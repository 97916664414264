const getUrl = (db) => {
    if (db === 'Live Database') {
        return 'https://api.intuety.io/v1/semantic-lookup/similarity'
    }

    return 'https://api.staging.intuety.io/v1/semantic-lookup/similarity'
}

export default async function ComparisonCheck (sentence1, sentence2, algorithm, db, keycloak) {
    const body = {
        sentence_1: sentence1,
        sentence_2: sentence2,
        algorithm: algorithm
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Auth-Token': keycloak.tokenParsed?.api_token
        },
        body: JSON.stringify(body)
    }

    const result = await fetch(getUrl(db), options).then(data => {
        return data.json()
    })

    return result
}
