
function getMidnight () {
    const endTime = new Date()
    endTime.setHours(0, 0, 0, 0)
    return endTime
}

export default function fetchJobs (status, setJobs, setJobCount, timeLimit, currentClients) {
    let startTime
    let endTime

    switch (timeLimit) {
    case 'Today':
        endTime = new Date()
        startTime = getMidnight()
        break
    case 'Yesterday':
        endTime = getMidnight()
        startTime = getMidnight()
        startTime.setDate(startTime.getDate() - 1)
        break
    case 'Last 7 Days':
        endTime = getMidnight()
        startTime = getMidnight()
        startTime.setDate(startTime.getDate() - 7)
        break
    case 'Last 30 Days':
        endTime = getMidnight()
        startTime = getMidnight()
        startTime.setDate(startTime.getDate() - 30)
        break
    case 'This Month':
        endTime = new Date()
        startTime = getMidnight()
        startTime.setDate(1)
        break
    case 'Last Month':
        endTime = getMidnight()
        endTime.setDate(1)
        startTime = getMidnight()
        startTime.setDate(1)
        startTime.setMonth(endTime.getMonth() - 1)
        break
    case 'This Year':
        endTime = getMidnight()
        startTime = getMidnight()
        startTime.setDate(1)
        startTime.setMonth(0)
        break
    case 'Last Year':
        endTime = getMidnight()
        endTime.setDate(1)
        endTime.setMonth(0)
        endTime.setFullYear(endTime.getFullYear() - 1)
        startTime = getMidnight()
        startTime.setDate(1)
        startTime.setMonth(0)
        startTime.setFullYear(endTime.getFullYear() - 2)
        break

    default:
        alert('time limit not recognised')
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Auth-Token': 'ACan-flGf7-xlEHzJ-guT'
        },

        body: JSON.stringify({
            lower_time_limit: startTime.getTime(),
            upper_time_limit: endTime.getTime(),
            start_point: 0,
            size: 10000
        })
    }

    fetch(`https://api.intuety.io/aurora/api/status/jobs/fetch-${status}`, options).then(result => {
        return result.json()
    }).then(data => {
        if (currentClients.length > 0) {
            setJobs([...data.hits.hits.filter(item => currentClients.includes(item._source.Client_ID))])
            setJobCount([...data.hits.hits.filter(item => currentClients.includes(item._source.Client_ID))].length)
        } else {
            setJobs([...data.hits.hits])
            setJobCount(data.hits.total)
        }
    })
}
