import React from 'react'
import EditButton from '../SearchTriples/EditButton'
import DeleteButton from '../SearchTriples/DeleteButton'

function TripleCardTableButtons (props) {
    function setEdited () {
        props.updateResponse(props.id, 'swap')
    }

    function setDeleted () {
        props.updateResponse(props.id, 'delete')
    }

    return (
        <span>
            <EditButton activity={props.activity}
                risk={props.risk}
                mitigation={props.mitigation}
                id={props.id}
                setEdited={setEdited}
                db={props.db}
                card={true}/>
            <DeleteButton id={props.id} setDeleted={setDeleted} db={props.db}/>
        </span>
    )
}

export default TripleCardTableButtons
