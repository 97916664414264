function fetchPublisherList (keycloak) {
    const options = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Auth-Token': keycloak.tokenParsed?.api_token
        }
    }
    return fetch('https://api.intuety.io/aurora/api/safety-briefing/fetch-publisher-list', options).then(result => {
        return result.json()
    }).then(list => {
        return list
    })
}

export default fetchPublisherList
