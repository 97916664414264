import React from 'react'
import { Button } from 'react-bootstrap'
import PublisherInput from '../PublisherInput'

function FieldsInput (props) {
    function checkAllFilled () {
        if (props.formConfig[0]) {
            if (!props.text) {
                return false
            }
        }

        if (props.formConfig[1]) {
            if (!props.tags) {
                return false
            }
        }

        if (props.formConfig[2]) {
            if (!props.doctype) {
                return false
            }
        }

        if (props.formConfig[3]) {
            if (!props.publisher) {
                return false
            }
        }

        if (props.formConfig[4]) {
            if (typeof props.isPrivate !== 'boolean') {
                return false
            }
        }

        if (!props.formConfig.includes(true)) {
            return false
        }

        return true
    }

    function displayText () {
        if (props.formConfig[0]) {
            return (
                <>
                    <label htmlFor="text">Text</label><br />
                    <textarea id="tags" rows="2" cols="30" value={props.text ? props.text : ''} onChange={(e) => { props.setText(e.target.value) }}/><br /><br />
                </>
            )
        }
    }

    function displayTags () {
        if (props.formConfig[1]) {
            return (
                <>
                    <label htmlFor="tags">Tags</label><br />
                    <textarea id="tags" rows="2" cols="30" value={props.tags ? props.tags : ''} onChange={(e) => { props.setTags(e.target.value) }}/><br /><br />
                </>
            )
        }
    }

    function displayDocType () {
        if (props.formConfig[2]) {
            return (
                <>
                    <label htmlFor="doctype">Document Type</label><br />
                    <input type="text" id="doctype" value={props.doctype ? props.doctype : ''} onChange={(e) => { props.setDoctype(e.target.value) }}/><br /><br />
                </>
            )
        }
    }

    function displayPublisher () {
        if (props.formConfig[3]) {
            return (
                <>
                    <label htmlFor="publisher">Publisher</label><br />
                    <PublisherInput setPublisher={props.onPublisherChange} publisher={props.publisher ? props.publisher : ''} />
                </>
            )
        }
    }

    function displayPrivate () {
        if (props.formConfig[4]) {
            return (
                <>
                    <label htmlFor="priv">Public or Private</label><br />
                    <select id="priv" onChange={props.onPrivateChange}>
                        <option value="initial" selected={typeof props.isPrivate === 'undefined'} hidden>Not Selected</option>
                        <option value="private" selected={!!props.isPrivate}>Private</option>
                        <option value="public" selected={!!(typeof props.isPrivate === 'boolean' && !props.isPrivate)}>Public</option>
                    </select><br /><br />
                </>
            )
        }
    }

    function displaySubmitButton () {
        if (checkAllFilled()) {
            return (
                <Button onClick={props.onSubmit}>Submit</Button>
            )
        } else {
            return <Button disabled>Submit</Button>
        }
    }

    return (
        <>
            <form>
                <label>Title: {props.briefing ? props.briefing._source.Title : 'Fetching'}</label><br /><br />
                <label>ID: {props.briefing ? props.briefing._id : 'Fetching'}</label><br /><br />
                {displayText()}
                {displayTags()}
                {displayDocType()}
                {displayPublisher()}
                {displayPrivate()}
                {displaySubmitButton()}<br /><br />
                <Button variant="danger" onClick={() => { props.setShowDeleteModal(true) }}>Delete</Button>
            </form>
        </>
    )
}

export default FieldsInput
