import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, FormControl, Button } from 'react-bootstrap'
import { useKeycloak } from '@react-keycloak/web'

function JobIdSearch (props) {
    const { keycloak } = useKeycloak()
    const [jobId, setJobId] = useState(useParams().jobId)

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            searchByJobId()
        }
    }

    function searchByJobId () {
        props.setProcessing(true)
        console.log(`Searching By Job Id ${jobId}`)
        fetchStatus()
    }

    function jobIdInput (event) {
        setJobId(event.target.value)
    }

    function fetchStatus () {
        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token
            },
            body: JSON.stringify({
                job_id: jobId
            })
        }
        fetch('https://api.intuety.io/aurora/api/status/fetch', options).then(response => {
            console.log(`status code received: ${response.status}`)
            return response.json()
        }).then((data) => {
            props.setStatusLog(data)
        }).catch(error => {
            console.log(`Error received: ${error}`)
        })
    }

    useEffect(() => {
        if (jobId) {
            fetchStatus()
        }
    }, [])

    return (
        <Form inline>
            <FormControl type='text'
                placeholder='Job ID'
                className="mr-sm-2"
                name='jobId'
                value={jobId}
                onChange={jobIdInput}
                onKeyPress={handleKeyDown} />
            <Button variant='outline-success' onClick={searchByJobId}> Search </Button>
        </Form>
    )
}

export default JobIdSearch
