import React from 'react'
import { Modal } from 'react-bootstrap'

function ViewTextModal (props) {
    function handleClose () {
        props.setShow(false)
    }

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Text</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{props.text}</p>
            </Modal.Body>
        </Modal>
    )
}

export default ViewTextModal
