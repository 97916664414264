import React from 'react'
import StatusLogRow from './StatusLogRow'

function StatusLogTable (props) {
    if (props.statusLog.length) {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Job ID</th>
                        <th>Status</th>
                        <th>Remarks</th>
                        <th>Time</th>
                    </tr>
                    {props.statusLog.map(status => {
                        return <StatusLogRow key={status._id} status={status}/>
                    })}
                </thead>
            </table>
        )
    } else {
        if (props.processing) {
            return <p>Processing <img src="https://www.wallies.com/filebin/images/loading_apple.gif" height="10" alt='loading'/> </p>
        } else {
            return <p>Nothing to show</p>
        }
    }
}

export default StatusLogTable
