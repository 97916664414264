import React, { useState } from 'react'
import { Button, Container, Row, Tabs, Tab } from 'react-bootstrap'
import JobIdSearch from './JobIdSearch'
import JSONPretty from 'react-json-pretty'
import StatusLogTable from './StatusLogTable'

function StatusChecker () {
    const [statusLog, setStatusLog] = useState({})
    const [processing, setProcessing] = useState(false)

    // Used to format and print the Pretty Print Data Log
    function PrettyPrintLog () {
        if (statusLog.length) {
            return statusLog.map(log => <JSONPretty id="json-pretty" key={log._id} data={log}></JSONPretty>)
            // Maps each of the logs to a json pretty print output
        } else {
            if (processing) {
                return <p>Processing <img src="https://www.wallies.com/filebin/images/loading_apple.gif" height="10" alt='loading'/> </p>
            } else {
                return <p>Nothing to show</p>
            }
        }
    }

    function clearLogs () {
        setStatusLog({})
        setProcessing(false)
    }

    return (
        <Container id='status_checker'>
            <Row>
                <h1> Status Page </h1>
            </Row>
            <Row id='search_form'>
                <JobIdSearch setStatusLog={setStatusLog} setProcessing={setProcessing}/>
                <div id="clear-button-holder">
                    <Button id="clear-button" stylevariant='outline-success' onClick={clearLogs}> Clear </Button>
                </div>
            </Row>
            <Row><br></br></Row>
            <Tabs defaultActiveKey="tableView">
                <Tab eventKey="tableView" title="Table View">
                    <StatusLogTable statusLog={statusLog} processing={processing}/>
                </Tab>
                <Tab eventKey="prettyPrint" title="Pretty Print">
                    <PrettyPrintLog />
                </Tab>
            </Tabs>

        </Container>
    )
}

export default StatusChecker
