import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Input,
    Container
} from "@mui/material"
import PaginationTableFooter from "../Components/PaginationTableFooter"
import { useKeycloak } from "@react-keycloak/web"
import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import { getAllClients } from "../utils"

const Header = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin: 0px 20px 0px 20px;
`


function Clients() {

    const [clients, setClients] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [filter, setFilter] = useState("")
    const [filteredClients, setFilteredClients] = useState([])

    const { keycloak } = useKeycloak()

    useEffect(() => {
        getClients()
    }, [])

    useEffect(() => {
        setFilteredClients(clients.filter((client) => {
            return client.clientName.toLowerCase().includes(filter.toLowerCase())
        }))
    }, [filter, clients])

    const getClients = async () => {
        const response = await getAllClients(keycloak.tokenParsed?.internal_services_token)
        setClients(response)
        setFilteredClients(response)
    }

    const handleChangePage = (newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (rows) => {
        setRowsPerPage(rows)
    }

    const handleFilterInput = (event) => {
        setFilter(event.target.value)
    }

    return (
        <>
            <Container>
                <Header maxWidth='md'>
                    <h1> Clients</h1>

                    <Input placeholder='Filter' initialValue={filter} label='filter' onChange={handleFilterInput} />

                </Header>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>#</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Created</TableCell>
                                <TableCell>Modified</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredClients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredClients
                            ).map((client) => {
                                return (
                                    <TableRow key={client.userId}>
                                        <TableCell> {client.clientId} </TableCell>
                                        <TableCell> {client.clientName} </TableCell>
                                        <TableCell> {client.created} </TableCell>
                                        <TableCell> {client.modified} </TableCell>
                                        <TableCell> {client.status} </TableCell>
                                        <TableCell> {client.delete} </TableCell>


                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <PaginationTableFooter
                            count={clients.length}
                            changeTablePage={handleChangePage}
                            changeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default Clients