import React, { useEffect, useState } from 'react'

const ComparisonResults = ({ result }) => {
    const [results, setResults] = useState([])
    const [reload, setReload] = useState(false)
    useEffect(() => {
        if (result && JSON.stringify(result) !== JSON.stringify(results[0])) {
            const tmp = results
            tmp.unshift(result)
            setResults(tmp)
            setReload(!reload)
        }
    }, [result])

    if (results.length > 0) {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Sentence 1</th>
                        <th>Sentence 2</th>
                        <th>Algorithm</th>
                        <th>Similarity</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((res, count) => {
                        return (
                            <tr key={count}>
                                <td>{res.sentence_1}</td>
                                <td>{res.sentence_2}</td>
                                <td>{res.algorithm}</td>
                                <td>{res.similarity}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        )
    } else {
        return <p>Compare two sentences</p>
    }
}

export default ComparisonResults
