import React from 'react'
import { Button, Modal } from 'react-bootstrap'

// TO DO: Display successful and failed creations

const SubmitModal = (props) => {
    const getUrl = () => {
        if (props.db === 'Live Database') {
            return 'https://app.intuety.io/api/v2/users/bulk'
        }

        return 'https://app.staging.intuety.io/api/v2/users/bulk'
    }

    const onSubmit = async () => {
        const csvContent = props.users.map(e => e.join(',')).join('\n') + '\n'
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })

        const data = new FormData()
        data.append('file', blob)

        const request = new XMLHttpRequest()

        request.onreadystatechange = () => {
            if (request.readyState === XMLHttpRequest.DONE) {
                if (request.status === 200) {
                    const res = JSON.parse(request.response)

                    if (!res.error) {
                        handleClose()
                        props.showResult(res.data)
                    } else {
                        alert(`Something went wrong: ${res.error}`)
                    }
                } else {
                    alert('Request was not successful')
                }
            }
        }

        request.open('POST', getUrl())
        request.setRequestHeader('token', 'development_token')
        request.send(data)

        // window.open(encodeURI("data:text/csv;charset=utf-8," + csvContent))
    }

    const handleClose = () => props.setShow(false)

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Submit new users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to submit these users?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SubmitModal
