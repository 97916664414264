export const deleteSector  = async (token, id) => {
    const options = {
        method: 'DELETE',
        mode: 'cors',
        headers: {
            token: token
        }
    }
    const url = `https://app.intuety.io/api/v2/sector/${id}`

    const res = await fetch(url, options)
    const response = await res.json()
    return response
}
