import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { getAllClients } from '../utils'

function DeleteProhibitedEquipmentModal (props) {
    const [clients, setClients] = useState([])
    const { keycloak } = useKeycloak()

    const handleClose = () => props.setShow(false)

    useEffect(() => {
        const fetchClients = async () => {
            const res = await getAllClients()
            setClients(res)
        }

        fetchClients()
    })

    function getURL () {
        if (props.db === 'Live Database') {
            return `https://api.intuety.io/aurora/api/prohibited-equipment/${props.item._id}`
        } else if (props.db === 'Staging Database') {
            return `https://api.staging.intuety.io/aurora/api/prohibited-equipment/${props.item._id}`
        }
    }

    const onSubmit = async () => {
        try {
            const options = {
                method: 'DELETE',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': keycloak.tokenParsed?.api_token
                }
            }

            const response = await fetch(getURL(), options)

            if (response.status === 200) {
                alert('Deleted item successfully')
            } else {
                alert('Problem deleting item')
            }

            handleClose()
            setTimeout(props.updateSearch(), 20)
        } catch {
            alert('Unexpected error when deleting item')
        }
    }

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Prohibited Equipment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to delete the following item?</p>
                <p>Name: {props.item ? props.item._source.name : null}</p>
                <p>Associated Activities: </p>
                <ul>
                    {props.item ? props.item._source.associated_activities.map((activity, index) => <li key={index}>{activity}</li>) : null}
                </ul>
                <p>Clients: </p>
                <ul>
                    {props.item ? props.item._source.client_ids ? props.item._source.client_ids.map(id => <li key={id}>{id} -  <i>{clients.find(client => client.clientId === id).clientName}</i></li>) : null : null}
                </ul>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="danger" onClick={onSubmit}>
                    Delete Item
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteProhibitedEquipmentModal
