import React, { useState } from 'react'
import { Container, Row } from 'react-bootstrap'
import SearchButton from './SearchButton'

import './SafetyBriefings.css'
import SafetyBriefingDisplay from './SafetyBriefingDisplay'
import SearchOptions from './SearchOptions'
import { useKeycloak } from '@react-keycloak/web'

function SafetyBriefings () {
    const [title, setTitle] = useState('')
    const [tags, setTags] = useState('')
    const [publisher, setPublisher] = useState('')
    const [doctype, setDocType] = useState('')
    const [response, setResponse] = useState(null)
    const [notFound, setNotFound] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const { keycloak } = useKeycloak()

    function updateTitle (e) {
        setTitle(e.target.value)
    }

    function updateTags (e) {
        setTags(e.target.value)
    }

    function updatePublisher (value) {
        setPublisher(value)
    }

    function updateDocType (e) {
        switch (e.target.value) {
        case 'N/A':
            setDocType('')
            break
        case 'Safety Briefing':
            setDocType('briefing')
            break
        case 'Safety Alert':
            setDocType('alert')
            break
        case 'Company Standards':
            setDocType('company standard')
            break
        case 'Company Policy':
            setDocType('company policy')
            break
        case 'Toolbox Talk':
            setDocType('toolbox talk')
            break
        default:
            alert('somethings gone wrong')
        }
    }

    function UpdateSafetyBriefingInfo (title, tags, text, publisher, docType, isPrivate, id) {
        const body = {}

        if (title) {
            body.Title = title
        }

        if (tags) {
            body.Tags = tags
        }

        if (text) {
            body.Text = text
        }

        if (publisher) {
            body.Publisher = publisher
        }

        if (docType) {
            body.DocumentType = docType.toLowerCase()
        }

        if (typeof isPrivate === 'boolean') {
            body.Private = isPrivate
        }

        console.log(body)

        const options = {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token
            },
            body: JSON.stringify(body)
        }

        fetch(`https://api.intuety.io/aurora/api/safety-briefing/file/${id}`, options).then(response => {
            console.log(`response code recieved ${response.status}`)
            return true
        }).catch(err => {
            alert('Problem updating safety briefing information')
            console.log(err)
        })
    }

    function displayResponse () {
        if (notFound) {
            return <p>No Documents Found</p>
        } else if (response === 'error') {
            alert('Error with search')
        } else if (response) {
            return (
                response.map(safetyBriefing => {
                    return (
                        <SafetyBriefingDisplay
                            Title={safetyBriefing.Title}
                            Filename={safetyBriefing.Filename}
                            Tags={safetyBriefing.Tags}
                            Text={safetyBriefing.Text}
                            Publisher={safetyBriefing.Publisher}
                            DocumentType={safetyBriefing.DocumentType}
                            isPrivate={safetyBriefing.Private}
                            id={safetyBriefing.id}
                            refresh={refresh}
                            key={safetyBriefing.id}
                            setRefresh={setRefresh}
                            updateInfo={UpdateSafetyBriefingInfo}
                        />
                    )
                })
            )
        }
    }

    return (
        <Container>
            <Row>
                <h1>Safety Briefings</h1>
            </Row>
            <SearchOptions
                updateTitle={updateTitle}
                updateTags={updateTags}
                updateDocType={updateDocType}
                updatePublisher={updatePublisher}
                publisher={publisher}
            />
            <Row>
                <SearchButton
                    title={title}
                    tags={tags}
                    doctype={doctype}
                    publisher={publisher}
                    setResponse={setResponse}
                    setNoneFound={setNotFound}
                />
            </Row>
            <Row>
                {displayResponse()}
            </Row>
        </Container>
    )
}

export default SafetyBriefings
