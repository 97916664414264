import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

import DownloadBriefing from './DownloadBriefing'
import EditSafetyBriefingModal from './EditSafetyBriefingModal'
import ViewTextModal from './ViewTextModal'

function SafetyBriefingDisplay (props) {
    const [showModal, setShowModal] = useState(false)
    const [showTextModal, setShowTextModal] = useState(false)

    function onEdit () {
        setShowModal(true)
    }

    function onShowText () {
        setShowTextModal(true)
    }

    return (
        <div className="safety-briefing-info">
            <EditSafetyBriefingModal
                show={showModal}
                setShow={setShowModal}
                title={props.Title}
                tags={props.Tags}
                publisher={props.Publisher}
                docType={props.DocumentType}
                isPrivate={props.isPrivate}
                id={props.id}
                updateInfo={props.updateInfo}
                setRefresh={props.setRefresh}
            />
            <ViewTextModal
                show={showTextModal}
                text={props.Text}
                setShow={setShowTextModal}
            />
            <p>Title: <strong>{props.Title}</strong></p>
            <div className="SB-Grid">
                <div className="SB-Grid-Item">
                    <p>Tags: {props.Tags ? props.Tags : 'N/A'}</p>
                </div>
                <div className="SB-Grid-Item">
                    <p>Publisher: {props.Publisher ? props.Publisher : 'N/A'}</p>
                </div>
                <div className="SB-Grid-Item">
                    <p>Text: {props.Text ? <button onClick={onShowText}>View</button> : 'N/A'}</p>
                </div>
                <div className="SB-Grid-Item">
                    <p>Document Type: {props.DocumentType ? props.DocumentType : 'N/A'}</p>
                </div>
                <div className="SB-Grid-Item">
                    <p>Is Private: {typeof props.isPrivate === 'boolean' ? props.isPrivate.toString() : 'N/A'}</p>
                </div>
                <div className="SB-Grid-Item">
                    <p className="file-name">Filename: {props.Filename}</p>
                </div>
            </div>
            <div className="file-holder">
                <DownloadBriefing id={props.id} filename={props.Filename} />
                <Button className="download-button" onClick={onEdit}>Edit Info</Button>
            </div>
        </div>
    )
}

export default SafetyBriefingDisplay
