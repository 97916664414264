import { Button, Modal } from 'react-bootstrap'
import React from 'react'

function CheckDeleteModal (props) {
    function handleClose () {
        props.setShow(false)
    }

    function onDelete () {
        props.deleteBriefing()
        handleClose()
    }

    return (
        <Modal show={props.show} onHide={handleClose}>
            <div style={{ 'text-align': 'center' }}>
                <p>Are you sure you want to delete this Safety Briefing</p>
            </div>

            <div className="Delete-Button-Holder">
                <Button onClick={handleClose}>Cancel</Button>
                <Button variant="danger" onClick={onDelete}>Delete</Button>
            </div>
        </Modal>
    )
}

export default CheckDeleteModal
