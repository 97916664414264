import React, { useState, useEffect } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import MetricTile from './MetricTile'

function RecommendationsTile (props) {
    function getMidnight () {
        const endTime = new Date()
        endTime.setHours(0, 0, 0, 0)
        return endTime
    }

    const [recommendationsCount, setRecommendationsCount] = useState([])
    const { keycloak } = useKeycloak()

    useEffect(() => {
        fetchStats(props.timeLimit)
    }, [props.jobs])

    async function fetchStats (timeLimit) {
        let startTime
        let endTime

        switch (timeLimit) {
        case 'Today':
            endTime = new Date()
            startTime = getMidnight()
            break
        case 'Yesterday':
            endTime = getMidnight()
            startTime = getMidnight()
            startTime.setDate(startTime.getDate() - 1)
            break
        case 'Last 7 Days':
            endTime = getMidnight()
            startTime = getMidnight()
            startTime.setDate(startTime.getDate() - 7)
            break
        case 'Last 30 Days':
            endTime = getMidnight()
            startTime = getMidnight()
            startTime.setDate(startTime.getDate() - 30)
            break
        case 'This Month':
            endTime = new Date()
            startTime = getMidnight()
            startTime.setDate(1)
            break
        case 'Last Month':
            endTime = getMidnight()
            endTime.setDate(1)
            startTime = getMidnight()
            startTime.setDate(1)
            startTime.setMonth(endTime.getMonth() - 1)
            break
        case 'This Year':
            endTime = getMidnight()
            startTime = getMidnight()
            startTime.setDate(1)
            startTime.setMonth(0)
            break
        case 'Last Year':
            endTime = getMidnight()
            endTime.setDate(1)
            endTime.setMonth(0)
            endTime.setFullYear(endTime.getFullYear() - 1)
            startTime = getMidnight()
            startTime.setDate(1)
            startTime.setMonth(0)
            startTime.setFullYear(endTime.getFullYear() - 2)
            break

        default :
            alert('time limit not recognised')
        }

        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token

            }
        }

        let count = 0

        for (const bin of props.jobs) {
            for (const job of bin) {
                try {
                    const result = await fetch(`https://api.intuety.io/aurora/api/elasticdb/feedback/fetch/${job._id}/${props.status}`, options)

                    if (result.ok) {
                        const data = await result.json()
                        count += data.hits.total
                    }
                } catch (error) {
                    console.error(error)
                }
            }
        }

        setRecommendationsCount(count)
    }

    return (
        <>
            <MetricTile title={`Number of ${props.status} Recommendations:`} value={recommendationsCount} />
        </>
    )
}

export default RecommendationsTile
