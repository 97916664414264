import React from 'react'
import { Button } from 'react-bootstrap'

import { useKeycloak } from '@react-keycloak/web'

function AddTriple (props) {
    const { keycloak } = useKeycloak()

    function handleClick () {
        fetchTriple()
    }

    function getURL () {
        if (props.db === 'Live Database') {
            return 'https://api.intuety.io/aurora/api/elasticdb/otg/ingest/new-triple'
        } else if (props.db === 'Staging Database') {
            return 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/ingest/new-triple'
        }
    }

    function buildQuery () {
        const semanticMatches = []

        for (let i = 0; i < props.response.length; i++) {
            const match = {
                Activity: props.response[i].Activity,
                Risk: props.response[i].Risk,
                Mitigation: props.response[i].Mitigation,
                Activity_Cosine_Score: props.response[i].Cosine_Activity,
                Risk_Cosine_Score: props.response[i].Cosine_Risk,
                Mitigation_Cosine_Score: props.response[i].Cosine_Mitigation,
                Cosine_Score: props.response[i].Cosine_Score
            }
            semanticMatches.push(match)
        }

        const query = {
            Triple: {
                Activity: props.activity,
                Risk: props.risk,
                Mitigation: props.mitigation
            },
            Original_Triple: {
                Activity: props.original_activity,
                Risk: props.original_risk,
                Mitigation: props.original_mitigation
            },
            Semantic_Matches: semanticMatches,
            Accepted: false,
            notInOtgId: props.id
        }
        return JSON.stringify(query)
    }

    function fetchTriple () {
        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token
            },
            body: buildQuery()
        }
        fetch(getURL(), options).then(response => {
            console.log(`status code received: ${response.status}`)
        }).then(() => {
            props.removeTriple()
        }).catch(error => {
            console.log(`Error received: ${error}`)
        })
    }

    function displayButton () {
        if (props.disabled) {
            return <Button variant='outline-danger' onClick={handleClick} disabled> Reject Triple </Button>
        } else {
            return <Button variant='outline-danger' onClick={handleClick}> Reject Triple </Button>
        }
    }

    return (
        <div id="modal-button">
            {displayButton()}
        </div>

    )
}

export default AddTriple
