import React from 'react'
import { Button } from 'react-bootstrap'

function TriplesRow (props) {
    function displayDeleteModal () {
        props.setTriple(props.info)
        props.setIndex(props.index)
        props.showDelete(true)
    }

    function displayEditModal () {
        props.setTriple(props.info)
        props.showEdit(true)
    }

    return (
        <tr>
            <td>{props.info.Activity}</td>
            <td>{props.info.Risk}</td>
            <td>{props.info.Mitigation}</td>
            <td id="table-buttons">
                <span>
                    <Button variant='outline-success' onClick={displayEditModal}> Edit </Button>
                    <Button variant='outline-danger' onClick={displayDeleteModal}> Delete </Button>
                </span>
            </td>
        </tr>

    )
}

export default TriplesRow
