import React from 'react'
import { Button } from 'react-bootstrap'

import { useKeycloak } from '@react-keycloak/web'
import FileSaver from 'file-saver'

function DownloadBriefing (props) {
    const { keycloak } = useKeycloak()

    function downloadBriefing () {
        console.log('Downloading Briefing:', props.id)
        getBreifing()
    }

    function buildQuery () {

    }

    function getBreifing () {
        const options = {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token
            },
            body: buildQuery()
        }
        fetch(`https://api.intuety.io/aurora/api/safety-briefing/file/${props.id}`, options).then(response => {
            console.log(`response code recieved ${response.status}`)
            const blob = response.blob()
            return blob
        }).then((blob) => {
            FileSaver.saveAs(blob, props.filename)
        }).catch(error => {
            console.log(`Error received: ${error}`)
        })
    }

    return (
        <Button className="download-button" onClick={downloadBriefing}>Download Briefing</Button>
    )
}

export default DownloadBriefing
