function UpdateConfig (url, newConfig, keycloak) {
    console.log('updating config...')

    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Auth-Token': keycloak.tokenParsed?.api_token
        },
        body: JSON.stringify({ config: JSON.parse(newConfig) })
    }

    return fetch(url, options).then(response => {
        if (response.status !== 200) {
            throw new Error(`Error, status: ${response.status}, message: ${response.body}`)
        } else {
            console.log('Updated')
            return true
        }
    }).catch(err => {
        console.log(err.message)
        return false
    })
}

export default UpdateConfig
