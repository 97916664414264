import React from 'react'
import TriplesRow from './TriplesRow'

function TriplesTable (props) {
    function showDelete (value) {
        props.setShowDelete(value)
    }

    function showEdit (value) {
        props.setShowEdit(value)
    }

    function setIndex (index) {
        props.setIndex(index)
    }

    function setTriple (triple) {
        props.setTriple(triple)
    }

    if (props.info) {
        return (
            <table>
                <thead>
                    <tr>
                        <th>Activity</th>
                        <th>Risk</th>
                        <th>Mitigation</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.info.map((item, index) => {
                            return <TriplesRow key={index} info={item} showDelete={showDelete}
                                showEdit={showEdit} setTriple={setTriple} setIndex={setIndex}
                                index={index}/>
                        })
                    }
                </tbody>
            </table>
        )
    }
}

export default TriplesTable
