import React from 'react'
import { Form } from 'react-bootstrap'

function AddTriple (props) {
    function updateValue (e) {
        props.setValue(e.target.value)
    }

    return (
        <Form.Group controlId="exampleForm.ControlTextarea1" onChange={updateValue}>
            <Form.Label><h3 id="input-title">{props.title}</h3></Form.Label>
            <Form.Control as="textarea" rows="3"/>
        </Form.Group>
    )
}

export default AddTriple
