import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { useKeycloak } from '@react-keycloak/web'
import styled from 'styled-components'

import fetchConfig from './FetchConfig'

import './Config.css'
import UpdateConfig from './UpdateConfig'
import TextField from './TextField'
import ObjectField from './ObjectField'
import BooleanField from './BooleanField'
import NewFieldModal from './NewFieldModal'

const ConfigHeader = styled.div`
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 10;
    background-color: white;
    padding: 5px;
    display: flex;
    align-items: center;
`

const Warning = styled.p`
    color: red;
    margin-left: 5px;
`

function isArray (object) {
    return Object.prototype.toString.call(object) === '[object Array]'
}

function Config () {
    const [productionConfig, setProductionConfig] = useState(false)
    const [stagingConfig, setStagingConfig] = useState(false)
    const [productionWarning, setProductionWarning] = useState('')
    const [stagingWarning, setStagingWarning] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [modalEnv, setModalEnv] = useState('')

    const { keycloak } = useKeycloak()

    const updateValue = (path, value, environment) => {
        if (environment === 'production') {
            updateObjectValue(path, JSON.parse(productionConfig).configData, value, environment)
        } else {
            updateObjectValue(path, JSON.parse(stagingConfig).configData, value, environment)
        }
    }

    const checkValid = (object, environment, base = true) => {
        // Check to make sure no values are empty strings
        let valid = true

        if (isArray(object)) {
            for (const item of object) {
                if (item.length === 0) {
                    valid = false

                    if (environment === 'production') {
                        setProductionWarning('* Cannot have empty string value')
                    } else {
                        setStagingWarning('* Cannot have empty string value')
                    }
                }
            }
        } else {
            const keys = Object.keys(object)

            for (const key of keys) {
                if (typeof object[key] === 'object') {
                    if (!checkValid(object[key], environment, false)) {
                        valid = false
                    }
                } else {
                    if (object[key].length === 0) {
                        valid = false

                        if (environment === 'production') {
                            setProductionWarning('* Cannot have empty string value')
                        } else {
                            setStagingWarning('* Cannot have empty string value')
                        }
                    }
                }
            }
        }

        if (valid && base) {
            if (environment === 'production') {
                setProductionWarning('')
            } else {
                setStagingWarning('')
            }
        } else {
            return valid
        }
    }

    const addField = (name, type, environment) => {
        let object

        if (environment === 'production') {
            object = JSON.parse(productionConfig).configData
        } else {
            object = JSON.parse(stagingConfig).configData
        }

        if (object[name]) {
            alert('Field already exists')
        } else if (/\s/g.test(name) || name.length === 0) {
            alert('Name must not include spaces or be empty')
        } else {
            switch (type) {
            case 'object':
                object[name] = {}
                break
            case 'string':
                object[name] = ''
                break
            case 'boolean':
                object[name] = false
                break
            case 'number':
                object[name] = 0
                break
            default:
                alert('invalid type')
            }

            if (environment === 'production') {
                setProductionConfig(JSON.stringify({ configData: object }))
            } else {
                setStagingConfig(JSON.stringify({ configData: object }))
            }
        }
    }

    const updateObjectValue = (path, object, value, environment) => {
        object[path[0]] = value
        checkValid(object, environment)

        if (environment === 'production') {
            setProductionConfig(JSON.stringify({ configData: object }, undefined, 4))
        } else {
            setStagingConfig(JSON.stringify({ configData: object }, undefined, 4))
        }
    }

    const removeField = (path, environment) => {
        if (environment === 'production') {
            removeObjectField(path, JSON.parse(productionConfig).configData, environment)
        } else {
            removeObjectField(path, JSON.parse(stagingConfig).configData, environment)
        }
    }

    const removeObjectField = (path, object, environment) => {
        delete object[path[0]]
        checkValid(object, environment)

        if (environment === 'production') {
            setProductionConfig(JSON.stringify({ configData: object }, undefined, 4))
        } else {
            setStagingConfig(JSON.stringify({ configData: object }, undefined, 4))
        }
    }

    const onSave = async (environment) => {
        if (environment === 'production') {
            const result = await UpdateConfig('https://api.intuety.io/aurora/api/configdb/update', productionConfig, keycloak)

            if (result) {
                alert('Production config successfully updated')
            } else {
                alert('Problem updating production config')
            }
        } else if (environment === 'staging') {
            const result = await UpdateConfig('https://api.staging.intuety.io/aurora/api/configdb/update', stagingConfig, keycloak)

            if (result) {
                alert('Staging config successfully updated')
            } else {
                alert('Staging updating production config')
            }
        }
    }

    useEffect(() => {
        fetchConfig('https://api.intuety.io/aurora/api/configdb/fetch', setProductionConfig, keycloak)
        fetchConfig('https://api.staging.intuety.io/aurora/api/configdb/fetch', setStagingConfig, keycloak)
    }, [])

    const displayKeys = (environment) => {
        let config

        if (environment === 'production') {
            config = JSON.parse(productionConfig).configData
        } else {
            config = JSON.parse(stagingConfig).configData
        }

        const keys = Object.keys(config)
        return keys.map(key => {
            switch (typeof (config[key])) {
            case 'string':
                return <TextField objectKey={key} value={config[key]} path={[key]} environment={environment} update={updateValue} remove={removeField} />
            case 'number':
                return <TextField objectKey={key} value={config[key]} path={[key]} environment={environment} update={updateValue} remove={removeField} />
            case 'boolean':
                return <BooleanField objectKey={key} value={config[key]} path={[key]} environment={environment} update={updateValue} remove={removeField} />
            case 'object':
                return <ObjectField object={config[key]} path={[key]} environment={environment} update={updateValue} removeObject={removeField} />
            default:
                return <p>{typeof (config[key])}</p>
            }
        })
    }

    return (
        <Container>
            <NewFieldModal show={showModal} setShow={setShowModal} environment={modalEnv} addNewField={addField} />
            <Row>
                <h1>Config</h1>
            </Row>
            <Row>
                <Col>
                    <ConfigHeader>
                        <h3>Production</h3>
                        <Button
                            onClick={() => { onSave('production') }}
                            style={{ marginLeft: '1em' }}
                            disabled={productionWarning.length > 0}
                        >Save</Button>
                        <Warning>{productionWarning}</Warning>
                    </ConfigHeader>
                    {productionConfig ? displayKeys('production') : 'Fetching production...'}
                    {productionConfig ? <Button onClick={() => { setModalEnv('production'); setShowModal(true) }}>Add Field</Button> : ''}
                </Col>
                <Col>
                    <ConfigHeader>
                        <h3 style={{ display: 'inline' }}>Staging</h3>
                        <Button
                            onClick={() => { onSave('staging') }}
                            style={{ display: 'inline', marginLeft: '1em' }}
                            disabled={stagingWarning.length > 0}
                        >Save</Button>
                        <Warning>{stagingWarning}</Warning>
                    </ConfigHeader>
                    {stagingConfig ? displayKeys('staging') : 'Fetching staging...'}
                    {stagingConfig ? <Button onClick={() => { setModalEnv('staging'); setShowModal(true) }}>Add Field</Button> : ''}
                </Col>
            </Row>
        </Container>
    )
}

export default Config
