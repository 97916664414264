import React from 'react'
import { Pagination } from 'react-bootstrap'

function TriplesPageSelector (props) {
    function handleClick (e) {
        console.log(`Page Change Event: ${e.target.textContent}`)

        if (e.target.textContent === '«First' || e.target.textContent === '«') {
            props.setPage(1)
            props.setNewPage(true)
        } else if (e.target.text === '»Last' || e.target.textContent === '»') {
            props.setPage(props.max)
            props.setNewPage(true)
        } else if (e.target.text !== undefined) {
            props.setPage(parseInt(e.target.text))
            props.setNewPage(true)
        }
    }

    const items = []

    if (props.max < 5) {
        for (let number = 1; number <= props.max; number++) {
            items.push(
                <Pagination.Item key={number} active={number === props.page}>
                    {number}
                </Pagination.Item>
            )
        }
    } else if (props.page === 1) {
        for (let number = props.page; number <= props.page + 4; number++) {
            items.push(
                <Pagination.Item key={number} active={number === props.page}>
                    {number}
                </Pagination.Item>
            )
        }
    } else if (props.page === 2) {
        for (let number = props.page - 1; number <= props.page + 3; number++) {
            items.push(
                <Pagination.Item key={number} active={number === props.page}>
                    {number}
                </Pagination.Item>
            )
        }
    } else if (props.page < props.max - 1) {
        for (let number = props.page - 2; number <= props.page + 2; number++) {
            items.push(
                <Pagination.Item key={number} active={number === props.page}>
                    {number}
                </Pagination.Item>
            )
        }
    } else if (props.page === props.max - 1) {
        for (let number = props.page - 3; number <= props.page + 1; number++) {
            items.push(
                <Pagination.Item key={number} active={number === props.page}>
                    {number}
                </Pagination.Item>
            )
        }
    } else {
        for (let number = props.page - 4; number <= props.page; number++) {
            items.push(
                <Pagination.Item key={number} active={number === props.page}>
                    {number}
                </Pagination.Item>
            )
        }
    }

    return (
        <Pagination id="page-select" onClick={handleClick}>
            <Pagination.First>«</Pagination.First>
            {items}
            <Pagination.Last>»</Pagination.Last>
        </Pagination>
    )
}

export default TriplesPageSelector
