export async function checkTriple (triple, db, keycloak) {
    function getURL () {
        if (db === 'Live Database') {
            return 'https://api.intuety.io/aurora/api/elasticdb/otg/check-triple'
        } else if (db === 'Staging Database') {
            return 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/check-triple'
        }
    }

    function fetchTriple () {
        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token
            },
            body: JSON.stringify(triple)
        }
        return fetch(getURL(), options).then(response => {
            console.log(`status code received: ${response.status}`)
            return response.json()
        }).then((data) => {
            return data
        }).catch(error => {
            console.log(`Error received: ${error}`)
        })
    }

    return await fetchTriple()
}
