import React, { useState } from 'react'
import { Button, Container, Form, Row } from 'react-bootstrap'

import { useKeycloak } from '@react-keycloak/web'

import './UploadSafetyBriefing.css'
import PublisherInput from './PublisherInput'

function UploadSafetyBriefing () {
    const [file, setFile] = useState(null)
    const [publisher, setPublisher] = useState('')

    const [doctype, setDoctype] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isPrivate, setIsPrivate] = useState(false)
    const { keycloak } = useKeycloak()

    function onFileUpload () {
        setFile(document.getElementById('file-upload').files[0])
    }

    function onDoctypeSelect (e) {
        setDoctype(e.target.value)
    }

    function onPrivateSelect (e) {
        setIsPrivate(e.target.value)
    }

    function submit () {
        setLoading(true)
        SendSafetyBriefing(file, publisher, doctype, onResponse)
    }

    function onResponse (res) {
        setLoading(false)
        alert(res)
    }

    function displayPublisherChoice () {
        return (
            <>
                <PublisherInput setPublisher={setPublisher} publisher={publisher} keycloak={keycloak}/>
            </>
        )
    }

    function displayButton () {
        if (publisher && doctype && file && isPrivate) {
            return <Button onClick={submit}>Submit</Button>
        } else {
            return <Button onClick={submit} disabled>Submit</Button>
        }
    }

    function displayLoadingScreen () {
        if (loading) {
            return (
                <div className="loading-screen">
                    <div className="loading-text">
                        <h2>Uploading Safety Briefing...</h2>
                    </div>
                    <div>
                        <img src="https://www.wallies.com/filebin/images/loading_apple.gif" height="50" alt="Loading..." />
                    </div>
                </div>
            )
        }
    }

    function SendSafetyBriefing (file, publisher, doctype, onResponse) {
        const data = new FormData()
        data.append('file', file)
        data.append('Publisher', publisher)
        data.append('DocumentType', doctype)

        if (isPrivate === 'public') {
            data.append('Private', false)
        } else {
            data.append('Private', true)
        }

        const request = new XMLHttpRequest()

        request.onreadystatechange = function () {
            if (request.readyState === 4) {
                console.log(request.response)
                onResponse(request.response)
            }
        }

        request.open('POST', 'https://api.intuety.io/aurora/api/safety-briefing/file')
        request.setRequestHeader('auth-token', keycloak.tokenParsed?.api_token)
        request.send(data)
    }

    return (
        <Container>
            {displayLoadingScreen()}
            <Row>
                <h1>Upload Safety Briefings</h1>
            </Row>
            <Row className="form-holder">
                <Form>
                    <div className="upload-holder">
                        <Form.Group>
                            <Form.Control
                                type="file"
                                id="file-upload"
                                label="Safety Briefing"
                                accept="pdf"
                                onChange={onFileUpload}
                            />
                        </Form.Group>
                    </div>
                    <Form.Group>
                        <Form.Label>Publisher</Form.Label>
                        {displayPublisherChoice()}
                    </Form.Group>
                    <div>
                        <Form.Group>
                            <Form.Label>Document Type</Form.Label>
                            <Form.Control as="select" onChange={onDoctypeSelect}>
                                <option value="" selected disabled hidden>Choose here</option>
                                <option value="safety briefing">Safety Briefing</option>
                                <option value="guidance document">Guidance Document</option>
                                <option value='company standard'>Company Standards (SOPs etc)</option>
                                <option value='company ppolicy'>Company Policy</option>
                                <option value='toolbox talk'>Toolbox Talk</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <div>
                        <Form.Group>
                            <Form.Label>Public or Private</Form.Label>
                            <Form.Control as="select" onChange={onPrivateSelect}>
                                <option value="" selected disabled hidden>Choose here</option>
                                <option value="public">Public</option>
                                <option value="private">Private</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                </Form>
            </Row>
            <Row>
                <div className="button-holder">
                    {displayButton()}
                </div>
            </Row>
        </Container>
    )
}

export default UploadSafetyBriefing
