import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'

import { useKeycloak } from '@react-keycloak/web'

function SearchButton (props) {
    const { keycloak } = useKeycloak()

    function buildQuery () {
        const query = {}

        if (props.title) {
            query.Title = props.title
        }

        if (props.tags) {
            query.Tags = props.tags
        }

        if (props.doctype) {
            query.DocumentType = props.doctype
        }

        if (props.publisher) {
            query.Publisher = props.publisher
        }

        console.log(query)
        return JSON.stringify(query)
    }

    function searchSafetyBriefings () {
        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token
            },
            body: buildQuery()
        }
        fetch('https://api.intuety.io/aurora/api/safety-briefing/fetch', options).then(response => {
            console.log(`response code recieved ${response.status}`)

            if (response.status === 404) {
                return 404
            } else {
                return response.json()
            }
        }).then((data) => {
            if (data === 404) {
                props.setNoneFound(true)
                props.setResponse(null)
            } else if (data.statusCode === 400) {
                props.setNoneFound(false)
                props.setResponse('error')
            } else {
                console.log(data)
                props.setResponse(data)
                props.setNoneFound(false)
            }
        }).catch(error => {
            console.log(`Error received: ${error}`)
        })
    }

    useEffect(() => {
        if (props.refresh) {
            searchSafetyBriefings()
            props.setRefresh(false)
        }
    }, [props])

    return (
        <Button
            className="safety-briefing-search-button"
            onClick={searchSafetyBriefings}
        >Search</Button>
    )
}

export default SearchButton
