import React from 'react'
import { DropdownButton, Dropdown } from 'react-bootstrap'

function DatabaseSelection (props) {
    const handleSelect = (e) => {
        props.setDB(e)
    }

    return (
        <div className="App container" id="database-selector">

            <DropdownButton
                alignRight
                title={props.db}
                id="dropdown-menu-align-right"
                onSelect={handleSelect}
            >
                <Dropdown.Item eventKey="Live Database">Live Database</Dropdown.Item>
                <Dropdown.Item eventKey="Staging Database">Staging Database</Dropdown.Item>
            </DropdownButton>
        </div>
    )
}

export default DatabaseSelection
