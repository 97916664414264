function getURL (db) {
    if (db === 'Live Database') {
        return 'https://api.intuety.io/aurora/api/prohibited-equipment/search'
    } else if (db === 'Staging Database') {
        return 'https://api.staging.intuety.io/aurora/api/prohibited-equipment/search'
    }
}

function buildQuery (name, activity, clientID) {
    const conditions = []

    if (name.length > 0) {
        conditions.push({
            match: {
                name: name
            }
        })
    }

    if (activity.length > 0) {
        conditions.push({
            match: {
                associated_activities: activity
            }
        })
    }

    if (clientID.length > 0) {
        conditions.push({
            match_phrase: {
                client_ids: parseInt(clientID)
            }
        })
    }

    return JSON.stringify({
        query: {
            query: {
                bool: {
                    must: conditions
                }
            }
        }
    })
}

async function searchProhibitedEquipment (name, activity, clientID, db, keycloak, setSearchResults) {
    try {
        const options = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': keycloak.tokenParsed?.api_token
            },
            body: buildQuery(name, activity, clientID)
        }

        const response = await fetch(getURL(db), options)
        const data = await response.json()
        setSearchResults(data.hits.hits)
    } catch {
        alert('Unexpected error with search')
    }
}

export default searchProhibitedEquipment
