import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import DeleteButton from './DeleteButton'

function DeleteModal (props) {
    const [deleted, setDeleted] = useState(false)

    function handleClose () {
        props.setShow(false)
    }

    function displayValue () {
        if (props.triple._id) {
            return (
                <div>
                    <p><b> Are you sure you want to delete the following triple: </b></p>
                    <p><b>ID:</b> {props.triple._id}</p>
                    <p><b>Activity:</b> {props.triple.Activity}</p>
                    <p><b>Risk:</b> {props.triple.Risk}</p>
                    <p><b>Mitigation:</b> {props.triple.Mitigation}</p>
                </div>
            )
        }
    }

    useEffect(() => {
        if (deleted) {
            setDeleted(false)
            handleClose()
            setTimeout(() => { props.reloadSearch() }, 1000)
        }
    }, [props.triple, deleted])

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Delete Triple</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {displayValue()}
                <span>
                    <Button variant="outline-primary" onClick={handleClose}>Cancel</Button>
                    <DeleteButton id={props.triple._id} setDeleted={setDeleted} db={props.db}/>
                </span>
            </Modal.Body>
        </Modal>
    )
}

export default DeleteModal
