import { Button, Form, Modal } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { getAllClients } from '../utils'

function AddProhibitedEquipmentModal (props) {
    const [name, setName] = useState('')
    const [activities, setActivities] = useState([])
    const [clientIDs, setClientIDs] = useState([])
    const [clients, setClients] = useState(null)

    const { keycloak } = useKeycloak()

    const onActivityKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
            setActivities([...activities, event.target.value])
            event.target.value = ''
        }
    }

    const onClientKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()

            if (parseInt(event.target.value)) {
                setClientIDs([...clientIDs, parseInt(event.target.value)])
                event.target.value = ''
            } else {
                alert('Not an integer')
            }
        }
    }

    const onRemoveActivity = (pos) => {
        const tmp = activities
        tmp.splice(pos, 1)

        if (tmp.length === 0) {
            setActivities([])
        } else {
            setActivities([...tmp])
        }
    }

    const onRemoveClient = (pos) => {
        const tmp = clientIDs
        tmp.splice(pos, 1)

        if (tmp.length === 0) {
            setClientIDs([])
        } else {
            setClientIDs([...tmp])
        }
    }

    function getURL () {
        if (props.db === 'Live Database') {
            return 'https://api.intuety.io/aurora/api/prohibited-equipment'
        } else if (props.db === 'Staging Database') {
            return 'https://api.staging.intuety.io/aurora/api/prohibited-equipment'
        }
    }

    function getBody () {
        const body = {
            name: name,
            activities: activities
        }

        if (clientIDs.length > 0) {
            body.clientIDs = clientIDs
        }

        return JSON.stringify(body)
    }

    const onSubmit = async () => {
        try {
            const options = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': keycloak.tokenParsed?.api_token
                },
                body: getBody()
            }

            const response = await fetch(getURL(), options)

            if (response.status === 201) {
                alert('Created item successfully')
            } else {
                alert('Problem creating item')
            }

            handleClose()
            setTimeout(props.updateSearch(), 20)
        } catch {
            alert('An uncaught error occured with the request')
        }
    }

    useEffect(() => {
        setActivities([])
        setClientIDs([])
        setName('')
    }, [props.show])

    useEffect(() => {
        const fetchClients = async () => {
            const res = await getAllClients()
            setClients(res)
        }

        fetchClients()
    }, [])

    const handleClose = () => { props.setShow(false) }

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Prohibited Equipment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter name" onChange={(e) => setName(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Associated Activities</Form.Label>
                        <Form.Control type="text" placeholder="Enter activities" onKeyPress={onActivityKeyDown} />
                        {activities.map((activity, pos) => <p key={pos}> {activity}  <Button onClick={() => { onRemoveActivity(pos) }}>Delete</Button></p>)}
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Client ID(s) <i>hit enter for each client id</i></Form.Label>
                        <Form.Control type="text" placeholder="Enter client ids" onKeyPress={onClientKeyDown} />
                        {clientIDs.map((id, pos) => parseInt(id) ? <p key={pos}>{id} <i>{clients.find(client => client.clientId === id).clientName}</i> <Button onClick={() => { onRemoveClient(pos) }}>Delete</Button></p> : null)}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={onSubmit} disabled={!(name && activities.length > 0)}>
                    Add Prohibited Equipment
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddProhibitedEquipmentModal
