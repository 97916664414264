import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Input,
    Container,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    DialogContentText,
    Select,
    MenuItem
} from "@mui/material"
import PaginationTableFooter from "../Components/PaginationTableFooter"
import { useKeycloak } from "@react-keycloak/web"
import React, { useEffect, useState } from "react"
import styled from 'styled-components'
import {
    getAllRegions,
    createRegion,
    deleteRegion,
    editRegion
} from "../utils"

const Header = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin: 0px 20px 0px 20px;
`

function Regions() {

    const [regions, setRegions] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [filter, setFilter] = useState("")
    const [filteredRegions, setFilteredRegions] = useState([])
    const [regionsModal, setRegionsModal] = useState('')
    const [tempRegion, setTempRegion] = useState({})
    const [refresh, setRefresh] = useState(true)

    const { keycloak } = useKeycloak()

    const getRegions = async () => {
        const response = await getAllRegions(keycloak.tokenParsed?.internal_services_token)
        setRegions(response)
        setFilteredRegions(response)
    }

    useEffect( () => {
        getRegions()
    }, [refresh])

    useEffect(() => {
        var filtRegions = regions.filter((region) => {
            return region.name.toLowerCase().includes(filter.toLowerCase())
        })
        setFilteredRegions(filtRegions)
    }, [filter, regions])


    const handleAction = () => {
        regionsModal === 'Create ' && createRegion(keycloak.tokenParsed?.internal_services_token, tempRegion)
        regionsModal === 'Delete ' && deleteRegion(keycloak.tokenParsed?.internal_services_token, tempRegion.id)
        regionsModal === 'Edit ' && editRegion(keycloak.tokenParsed?.internal_services_token, tempRegion)
        setRegionsModal('')
        setTempRegion({
            name: '',
            parent: null
        })
        setRefresh(!refresh)
    }

    const handleCancel = () => {
        setRegionsModal('')
        setTempRegion({})
    }

    const handleOpenModal = async (action, id) => {
        const temp = regions.filter((region) => {
            return region.id === id && region
        }).pop()

        if (action === 'Edit ' || action === 'Delete ') {
            setRegionsModal(action)
            await setTempRegion(temp)
        }

        if (action === 'Create ') {
            setRegionsModal(action)
        }
    }

    const handleNameInput = (e) => {
        setTempRegion({...tempRegion, name: e.target.value})
    }

    const handleSelectChange = (e) => {
        setTempRegion({...tempRegion, parent: e.target.value})
    }

    const handleChangePage = (newPage) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (rows) => {
        setRowsPerPage(rows)
    }

    const handleFilterInput = (event) => {
        setFilter(event.target.value)
    }

    return (
        <>
            <Container>
                {
                // This Dialog is the modal for new or edit region
                }
                <Dialog open={regionsModal.length > 0} onClose={handleAction}>
                    <DialogTitle>{regionsModal} Region</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {regionsModal !== 'Delete ' ?
                                `You can ${regionsModal} region with the form below` :
                                'Do you confirm the deletion of this region ?'
                            }
                        </DialogContentText>
                        {regionsModal !== 'Delete ' && <div>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Region Name"
                                type="string"
                                fullWidth
                                value={tempRegion.name}
                                onChange={handleNameInput}
                            />
                            <Select
                                id="parent"
                                label="Parent"
                                onChange={handleSelectChange}
                                fullWidth
                                defaultValue={tempRegion.parent || 0}
                            >
                                <MenuItem value={0}>{'None'}</MenuItem>
                                {/* eslint-disable-next-line array-callback-return*/}
                                {regions.map((region) => {
                                    return <MenuItem key={region.id} name={region.name} value={region.id}>{region.name}</MenuItem>
                                })}
                            </Select>
                        </div>}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button onClick={handleAction}>{regionsModal}</Button>
                    </DialogActions>
                </Dialog>

                {
                // This is the main component
                }
                <Header maxWidth='md'>
                    <h1> Regions</h1>
                    <Button variant='contained' color='inherit' onClick={() => setRegionsModal('Create ')}>Add Region</Button>
                    <Input placeholder='Filter By Region' initialValue={filter} label='filter' onChange={handleFilterInput} />
                </Header>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> # </TableCell>
                                <TableCell> Name </TableCell>
                                <TableCell> Parent </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0
                                ? filteredRegions.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : filteredRegions
                            ).map((region) => {
                                return (
                                    <TableRow key={region.id}>
                                        <TableCell> {region.id} </TableCell>
                                        <TableCell> {region.name} </TableCell>
                                        <TableCell> {region.parent ? regions.filter((item) => item.id === region.parent && region).pop().name : '-'} </TableCell>
                                        <TableCell><Button variant='contained' color='inherit' onClick={() => handleOpenModal('Edit ', region.id)}>Edit</Button></TableCell>
                                        <TableCell><Button variant='contained' color='inherit' onClick={() => handleOpenModal('Delete ', region.id)}>Delete</Button></TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                        <PaginationTableFooter
                            count={regions.length}
                            changeTablePage={handleChangePage}
                            changeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Table>
                </TableContainer>
            </Container>
        </>
    )
}

export default Regions
