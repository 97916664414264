function findTriple (field, phrase, db, exactMatch, setMatches, keycloak) {
    if (field === 'Select Field' || phrase === '') {
        alert('Missing fields on search')
        return null
    }

    let fieldQuery

    if (exactMatch) {
        fieldQuery = {
            match_phrase: {}
        }
        fieldQuery.match_phrase[field] = phrase
    } else {
        fieldQuery = {
            match: {}
        }
        fieldQuery.match[field] = phrase
    }

    let query = {
        size: 10000,
        query: {
            bool: {
                must: [
                    fieldQuery
                ]
            }
        }
    }

    query = JSON.stringify(query)

    let url

    if (db === 'Live Database') {
        url = 'https://api.intuety.io/aurora/api/elasticdb/otg/lookup'
    } else {
        url = 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/lookup'
    }

    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Auth-Token': keycloak.tokenParsed?.api_token
        },
        body: query
    }

    fetch(url, options).then(response => {
        return response.json()
    }).then(data => {
        setMatches(data.hits.hits)
    }).catch(() => {
        alert('Error on search')
    })
}

export default findTriple
