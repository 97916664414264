import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Form } from 'react-bootstrap'
import styled from 'styled-components'
import { getAllClients } from '../utils'
import { useKeycloak } from '@react-keycloak/web'

const ActiveClientHolder = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #CFCFCF;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 5px;
    gap: 10px;
`

const RemoveSymbol = styled.div`
    &:hover {
        cursor: pointer;
    }
`

const ActiveClient = (props) => {
    return (
        <ActiveClientHolder>
            <div>{props.clientName}</div>
            <RemoveSymbol onClick={() => props.removeClient(props.clientId, props.clientName)}>X</RemoveSymbol>
        </ActiveClientHolder>
    )
}

const ListHolder = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

const SuggestionHolder = styled.div`
    position: relative;
`

const ClientDropdown = (props) => {
    const [clients, setClients] = useState([])
    const [currentClientNames, setCurrentClientNames] = useState([])
    const [isAll, setIsAll] = useState(true)
    const [isNewClient, setIsNewClient] = useState(false)
    const [_clientSuggestions, setClientSuggestions] = useState([])

    const { keycloak } = useKeycloak()

    const addClient = (id, name) => {
        document.getElementById('client-dropdown').innerHTML = ''
        setClientSuggestions([])
        setIsNewClient(false)
        setCurrentClientNames([...currentClientNames, name])
        props.setCurrentClients([...props.currentClients, String(id)])
    }

    const removeClient = (id, name) => {
        setCurrentClientNames(currentClientNames.filter(item => item !== name))
        props.setCurrentClients(props.currentClients.filter(item => item !== id))
    }

    useEffect(() => {
        const fetchClients = async () => {
            const res = await getAllClients(keycloak.tokenParsed?.internal_services_token)
            setClients([...res])
        }

        fetchClients()
    }, [])

    const onChange = (e) => {
        document.getElementById('client-dropdown').innerHTML = ''
        const suggestions = clients.filter(client => client.clientName.toLowerCase().includes(e.target.value.toLowerCase())).filter(client => !currentClientNames.includes(client.clientName))
        setClientSuggestions(suggestions)

        for (const index in suggestions) {
            const suggestionDiv = document.createElement('DIV')
            suggestionDiv.setAttribute('id', `suggestion-${index}`)
            suggestionDiv.setAttribute('class', 'Suggestion')
            suggestionDiv.addEventListener('click', () => { addClient(suggestions[index].clientId, suggestions[index].clientName) })
            suggestionDiv.innerHTML = suggestions[index].clientName
            document.getElementById('client-dropdown').appendChild(suggestionDiv)
        }
    }

    const onKeyDown = () => {

    }

    if (isAll) {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    All Clients
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item>All Clients</Dropdown.Item>
                    <Dropdown.Item onClick={() => setIsAll(false)}>Custom Client List</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    } else {
        return (
            <ListHolder>
                <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                    {props.currentClients.map((clientId, pos) => (<ActiveClient key={pos} clientName={currentClientNames[pos]} clientId={clientId} removeClient={removeClient} />))}

                    <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Custom Client List
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => { setIsAll(true); props.setCurrentClients([]); setIsNewClient(false) }}>All Clients</Dropdown.Item>
                            <Dropdown.Item>Custom Client List</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>

                <div style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                    {
                        isNewClient
                            ? <SuggestionHolder>
                                <Form.Control
                                    placeholder="Type here"
                                    onKeyDown={onKeyDown}
                                    onChange={onChange}
                                />
                                <div id="client-dropdown" style={{ position: 'absolute', zIndex: '20', width: '100%' }} />

                            </SuggestionHolder>

                            : null
                    }

                    <Button onClick={() => { setIsNewClient(true) }} style={{ height: '40px' }}>Add Client</Button>
                </div>
            </ListHolder>
        )
    }
}

export default ClientDropdown
