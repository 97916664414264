import React from 'react'
import './App.css'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './authentication/keycloak'
import AppRouter from './AppRouter'

function App () {
    return (

        <ReactKeycloakProvider authClient={keycloak}>
            <div className="App">
                <style type="text/css">
                    {`
            .row {
                margin-bottom: 15px;
                margin-left: 0;
                margin-right: 0;
            }
        `}
                </style>
                <header className="App-header">
                    <AppRouter>
                    </AppRouter>
                </header>
            </div>
        </ReactKeycloakProvider>
    )
}

export default App
