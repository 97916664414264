import React from 'react'
import './App.css'
import Navigation from './Navigation'
import Status from './Status/Status'
import Triples from './Triples/SearchTriples/Triples'
import AddTriples from './Triples/AddTriples/AddTriples'
import PrivateRoute from './authentication/PrivateRoute'
import { useKeycloak } from '@react-keycloak/web'
import NewTriples from './Triples/NewTriples/NewTriples'
import Dashboard from './Dashboard/Dashboard'
import UploadSafetyBriefing from './SafetyBriefings/UploadSafetyBriefing'

import {
    BrowserRouter as Router,
    Switch
} from 'react-router-dom'
import SafetyBriefings from './SafetyBriefings/SafetyBriefings'
import Config from './Config/Config'
import TestDocuments from './TestDocuments/TestDocuments'
import MissingFields from './SafetyBriefings/MissingFields/MissingFields'
import FindAndReplace from './Triples/FindAndReplace/FindAndReplace'
import ProhibitedEquipment from './ProhibitedEquipment/ProhibitedEquipment'
import SemanticLookup from './SemanticLookup/SemanticLookup'
import UserCreation from './UserCreation'
import Clients from './Clients/Clients'
import Users from './Users/Users'
import Regions from './Regions/Regions'
import Sectors from './Sectors/Sectors'

function AppRouter () {
    const { initialized } = useKeycloak()

    if (!initialized) {
        return <div>Loading...</div>
    }

    return (
        <Router>
            <Navigation />
            <Switch>
                <PrivateRoute roles={['internal-staff']} path='/dashboard' comp={Dashboard} />
                <PrivateRoute roles={['internal-staff']} path='/status/:jobId?' comp={Status} />
                <PrivateRoute roles={['internal-staff']} path='/triples' comp={Triples} />
                <PrivateRoute roles={['internal-staff']} path='/add-triples' comp={AddTriples} />
                <PrivateRoute roles={['internal-staff']} path='/new-triples' comp={NewTriples} />
                <PrivateRoute roles={['internal-staff']} path='/safety-briefings' comp={SafetyBriefings} />
                <PrivateRoute roles={['internal-staff']} path='/upload-safety-briefing' comp={UploadSafetyBriefing} />
                <PrivateRoute roles={['internal-staff']} path='/safety-briefing-missing-fields' comp={MissingFields} />
                <PrivateRoute roles={['internal-staff']} path='/config' comp={Config} />
                <PrivateRoute roles={['internal-staff']} path='/test-documents' comp={TestDocuments} />
                <PrivateRoute roles={['internal-staff']} path='/find-and-replace' comp={FindAndReplace} />
                <PrivateRoute roles={['internal-staff']} path='/prohibited-equipment' comp={ProhibitedEquipment} />
                <PrivateRoute roles={['internal-staff']} path='/semantic-lookup' comp={SemanticLookup} />
                <PrivateRoute roles={['internal-staff']} path='/user-creation' comp={UserCreation} />
                <PrivateRoute roles={['internal-staff']} path='/clients' comp={Clients}/>
                <PrivateRoute roles={['internal-staff']} path='/users' comp={Users} />
                <PrivateRoute roles={['internal-staff']} path='/regions' comp={Regions} />
                <PrivateRoute roles={['internal-staff']} path='/sectors' comp={Sectors} />
                <PrivateRoute roles={['internal-staff']} path='' comp={Dashboard} />
            </Switch>
        </Router>
    )
}

export default AppRouter
