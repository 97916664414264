import React, { useEffect, useState, useCallback } from 'react'
import { Button, Form, Container, Row } from 'react-bootstrap'
import { checkTriple } from './CheckTriple'

import AddTriple from './AddTriple'
import RejectTripe from './RejectTriple'
import TripleCardTableButttons from './TripleCardTableButtons'
import { useKeycloak } from '@react-keycloak/web'

function TripleCard (props) {
    const [valuesInitialised, setValuesInitialised] = useState(false)
    const [activity, setActivity] = useState('')
    const [risk, setRisk] = useState('')
    const [mitigation, setMitgation] = useState('')
    const [response, setResponse] = useState(null)

    const { keycloak } = useKeycloak()

    function updateActivity (e) {
        setActivity(e.target.value)
    }

    function updateRisk (e) {
        setRisk(e.target.value)
    }

    function updateMitigation (e) {
        setMitgation(e.target.value)
    }

    function rejectTriple () {
        props.setPreviousTriple(activity, risk)
        document.getElementById(props.index).style.display = 'none'
        props.update(props.index)
    }

    function updateResponse (id, action) {
        const newResponse = []

        if (action === 'swap') {
            for (let i = 0; i < response.length; i++) {
                if (response[i].id === id) {
                    response[i].Activity = activity
                    response[i].Risk = risk
                    response[i].Mitigation = mitigation
                }

                newResponse.push(response[i])
                rejectTriple()
            }

            setResponse(newResponse)
        } else if (action === 'delete') {
            for (let i = 0; i < response.length; i++) {
                if (!(response[i].id === id)) {
                    newResponse.push(response[i])
                }
            }

            setResponse(newResponse)
        }
    }

    const semanticLookup = useCallback(async () => {
        const triple = { Activity: props.activity, Risk: props.risk, Mitigation: props.mitigation }
        const res = await checkTriple(triple, props.db, keycloak)
        setResponse(res)
    }, [props, keycloak])

    useEffect(() => {
        if (props.index === props.getCurrentCard()) {
            semanticLookup()
        }
    }, [props, semanticLookup])

    function displayEditor () {
        if (!valuesInitialised) {
            setActivity(props.activity)
            setRisk(props.risk)
            setMitgation(props.mitigation)
            setValuesInitialised(true)
        }

        return (
            <Form id='text-input'>
                <Form.Group>
                    <Form.Label id='text-label'>Activity</Form.Label>
                    <Form.Control as="textarea" rows="3" value={activity} onChange={updateActivity}></Form.Control>
                    {
                        props.previousActivity
                            ? <Button onClick={() => { setActivity(props.previousActivity) }}>Use Previous Risk</Button>
                            : null
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Label id='text-label'>Risk</Form.Label>
                    <Form.Control as="textarea" rows="3" value={risk} onChange={updateRisk}></Form.Control>
                    {
                        props.previousRisk
                            ? <Button onClick={() => { setRisk(props.previousRisk) }}>Use Previous Risk</Button>
                            : null
                    }
                </Form.Group>
                <Form.Group>
                    <Form.Label id='text-label'>Mitigation</Form.Label>
                    <Form.Control as="textarea" rows="3" value={mitigation} onChange={updateMitigation}></Form.Control>
                </Form.Group>
            </Form>
        )
    }

    function displayResponse () {
        if (response) {
            if (response.length === 0) {
                return <p><b>No similar indexes exist</b>- reccomend adding it to the ontology</p>
            } else {
                return (
                    <span>
                        <p id='response'><b>Found similar values</b>- check to make sure it is not a duplicate before adding</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Activity</th>
                                    <th>Risk</th>
                                    <th>Mitigation</th>
                                    <th>Cosine Score</th>
                                </tr>
                            </thead>
                            <tbody>
                                {response.map(index => {
                                    return (
                                        <tr key={index.id}>
                                            <td>{index.Activity}</td>
                                            <td>{index.Risk}</td>
                                            <td>{index.Mitigation}</td>
                                            <td>{index.Cosine_Score}</td>
                                            <td id="table-buttons">
                                                <TripleCardTableButttons id={index.id}
                                                    activity={activity}
                                                    risk={risk}
                                                    mitigation={mitigation}
                                                    db={props.db}
                                                    updateResponse={updateResponse} />
                                            </td>

                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </span>
                )
            }
        } else {
            return (
                <div>
                    <p>Processing <img src="https://www.wallies.com/filebin/images/loading_apple.gif" height="10" alt="Loading..." /> </p>
                </div>
            )
        }
    }

    function displayButtons () {
        if (response) {
            return (
                <span id="modal-buttons">
                    <RejectTripe activity={activity} risk={risk} mitigation={mitigation} removeTriple={rejectTriple} disabled={false} db={props.db} original_activity={props.activity} original_risk={props.risk} original_mitigation={props.mitigation} response={response} id={props.id} />
                    <AddTriple activity={activity} risk={risk} mitigation={mitigation} removeTriple={rejectTriple} disabled={false} db={props.db} original_activity={props.activity} original_risk={props.risk} original_mitigation={props.mitigation} response={response} id={props.id} />
                </span>
            )
        } else {
            return (
                <span id="modal-buttons">
                    <Button variant="outline-danger" onClick={rejectTriple} disabled>Reject</Button>
                    <AddTriple activity={activity} risk={risk} mitigation={mitigation} removeTriple={rejectTriple} disabled={true} db={props.db} />
                </span>
            )
        }
    }

    const getDisplay = () => {
        if (props.getCurrentCard() === props.index) {
            return 'inital'
        } else {
            return 'none'
        }
    }

    return (
        <div id={props.index} style={{ display: getDisplay() }}>
            <Container id='card'>
                <Row>
                    <h3>Process New Triple</h3>
                </Row>
                <Row>
                    <p><b>Edit Values</b>- correct any spelling/grammar mistakes here</p>
                    {displayEditor()}
                </Row>
                <Row>
                    {displayResponse()}
                </Row>
                <Row>
                    <br></br>
                    {displayButtons()}
                </Row>
            </Container>
        </div>
    )
}

export default TripleCard
