import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    border: 2px black solid;
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 1em;
    position: relative;
`

const InputHolder = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-right: 60px;
`

const CheckboxOn = styled.div`
    width: 40px;
    height: 40px;
    background-color: green;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }
`

const CheckboxOff = styled.div`
    width: 40px;
    height: 40px;
    background-color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    border-radius: 10px;

    &:hover {
        cursor: pointer;
    }
`

const RemoveButton = styled.div`
    width: 63px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 5px;
    border: 2px red solid;

    &:hover {
        cursor: pointer;
    }
`

function BooleanField ({ objectKey, value, path, environment, update, remove }) {
    const onRemove = () => {
        remove(path, environment)
    }

    const updateBool = () => {
        if (value) {
            update(path, false, environment)
        } else {
            update(path, true, environment)
        }
    }

    return (
        <Wrapper>
            <InputHolder>
                <p>{objectKey}:</p>
                {value ? <CheckboxOn onClick={updateBool}>True</CheckboxOn> : <CheckboxOff onClick={updateBool}>False</CheckboxOff>}
                <RemoveButton onClick={onRemove}>Remove</RemoveButton>
            </InputHolder>
        </Wrapper>
    )
}

export default BooleanField
