export const createRegion  = async (token, region) => {
    const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
            token: token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: region.name,
            parent: region.parent
        })
    }
    const url = `https://app.intuety.io/api/v2/region`

    const res = await fetch(url, options)
    const response = await res.json()
    return response
}
