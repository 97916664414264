import React from 'react'

import TriplesChecker from './TriplesChecker'

function Triples () {
    return (
        <TriplesChecker />
    )
}

export default Triples
