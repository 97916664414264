import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import PublisherInput from './PublisherInput'
import { useKeycloak } from '@react-keycloak/web'

function EditSafetyBriefingModal (props) {
    const [title, setTitle] = useState('')
    const [tags, setTags] = useState('')
    const [text, setText] = useState('')
    const [isPrivate, setIsPrivate] = useState('')
    const [publisher, setPublisher] = useState('')
    const [docType, setDocType] = useState('')
    const { keycloak } = useKeycloak()

    function updateTitle (e) {
        setTitle(e.target.value)
    }

    function updateTags (e) {
        setTags(e.target.value)
    }

    function updateDoctype (e) {
        if (e.target.value === 'N/A') {
            setDocType('')
        } else {
            setDocType(e.target.value)
        }
    }

    function updateIsPrivate (e) {
        setIsPrivate(e.target.value === 'private')
    }

    useEffect(() => {
        setTitle(props.title ? props.title : '')
        setTags(props.tags ? props.tags : '')
        setText(props.text ? props.text : '')
        setPublisher(props.publisher ? props.publisher : '')
        setDocType(props.docType ? props.docType : '')
        setIsPrivate(typeof props.isPrivate === 'boolean' ? props.isPrivate : 'N/A')
    }, [])

    function getSelected (option) {
        if (props.docType === option) {
            return 'selected'
        } else {
            return null
        }
    }

    function onUpdate () {
        props.updateInfo(title, tags, text, publisher, docType, isPrivate, props.id)
    }

    function choosePrivate () {
        if (typeof isPrivate === 'boolean') {
            return (
                <Form.Control
                    as="select"
                    onChange={updateIsPrivate}
                >
                    <option selected={isPrivate} value='private'>Private</option>
                    <option selected={!isPrivate} value='public'>Public</option>
                </Form.Control>
            )
        } else {
            return (
                <Form.Control
                    as="select"
                    onChange={updateIsPrivate}
                >
                    <option hidden selected>Not Selected</option>
                    <option value='private'>Private</option>
                    <option value='public'>Public</option>
                </Form.Control>
            )
        }
    }

    function handleClose () {
        props.setShow(false)
        props.setRefresh(true)
    }

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Safety Briefing</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                            type="text"
                            value={title}
                            onChange={updateTitle}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Tags</Form.Label>
                        <Form.Control
                            type="text"
                            value={tags}
                            onChange={updateTags}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Publisher</Form.Label>
                        <PublisherInput setPublisher={setPublisher} publisher={publisher} keycloak={keycloak} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Document Type</Form.Label>
                        <Form.Control
                            as="select"
                            value={docType}
                            onChange={updateDoctype}
                        >
                            <option selected={getSelected('')}>N/A</option>
                            <option value='safety briefing' selected={getSelected('safety briefing')}>Safety Briefing</option>
                            <option value='guidance document' selected={getSelected('guidance document')}>Guidance Document</option>
                            <option value='company standard' selected={getSelected('company standard')}>Company Standards (SOPs etc)</option>
                            <option value='company policy' selected={getSelected('company policy')}>Company Policy</option>
                            <option value='toolbox talk' selected={getSelected('toolbox talk')}>Toolbox Talk</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Private or Public</Form.Label>
                        {choosePrivate()}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                <Button variant="success" onClick={onUpdate}>Update</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditSafetyBriefingModal
