import React, { useCallback, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import TripleCards from './TripleCards'
import DatabaseSelection from '../DatabaseSelection'
import { useKeycloak } from '@react-keycloak/web'

function NewTriples () {
    const [triples, setTriples] = useState(null)
    const [processed, setProcessed] = useState(false)
    const [db, setDB] = useState('Live Database')
    const [previousActivity, setPreviousActvitiy] = useState(false)
    const [previousRisk, setPreviousRisk] = useState(false)
    const [continueFetching, setContinueFetching] = useState(true)

    const { keycloak } = useKeycloak()

    const fetchTriples = useCallback(async () => {
        if (continueFetching) {
            let url

            if (db === 'Live Database') {
                url = 'https://api.intuety.io/extracted/not-in-otg/'
            } else {
                url = 'https://api.staging.intuety.io/extracted/not-in-otg/'
            }

            const result = await fetch(url + '5', {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': keycloak.tokenParsed?.api_token
                }
            }).then(res => {
                return res.json()
            }).then(hits => {
                const tripleList = hits.map(hit => {
                    const object = hit._source
                    object.id = hit._id
                    return object
                })
                return tripleList
            }).catch((error) => {
                alert(`Something went wrong when fetching the triples: ${error.toString()}`)
            })

            if (typeof result === 'object') {
                if (result.length === 0) {
                    alert("It looks like there aren't any triples left to ingest, fingers crossed that is the case but check with the dev team just to makes sure")
                }

                setTriples(result)
                setProcessed(false)

                const timeStamp = Date.now()

                for (const triple of result) {
                    await fetch(url + triple.id, {
                        method: 'PUT',
                        mode: 'cors',
                        headers: {
                            'Content-Type': 'application/json',
                            'Auth-Token': keycloak.tokenParsed?.api_token
                        },
                        body: JSON.stringify({
                            Pulled_At: timeStamp
                        })
                    })
                }
            }
        }
    }, [continueFetching, keycloak, db])

    function setPreviousTriple (activity, risk) {
        setPreviousActvitiy(activity)
        setPreviousRisk(risk)
    }

    useEffect(() => {
        fetchTriples()
    }, [fetchTriples])

    return (
        <Container>
            <Row>
                <Col><h1 id="title"> Ingest Triples </h1></Col>
                <Col><DatabaseSelection db={db} setDB={setDB} /></Col>
            </Row>
            <Row>
                <label>
                    Continue fetching triples:
                    <input type="checkbox" checked={continueFetching} onChange={() => { setContinueFetching(!continueFetching) }} style={{ marginLeft: '3px' }} />
                </label>
            </Row>
            <Row>
                <TripleCards db={db} triples={triples} processed={processed} setProcessed={setProcessed}
                    fetchTriples={fetchTriples} setPreviousTriple={setPreviousTriple} previousActivity={previousActivity}
                    previousRisk={previousRisk} />
            </Row>
            <Row><br></br></Row>
        </Container>

    )
}

export default NewTriples
