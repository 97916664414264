import React, { useEffect, useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'

import EditButton from './EditButton'

function EditModal (props) {
    const [activity, setActivity] = useState('')
    const [risk, setRisk] = useState('')
    const [mitigation, setMitgation] = useState('')
    const [_valuesInitialised, setValuesInitialised] = useState(false)
    const [currentID, setCurrentID] = useState('')
    const [edited, setEdited] = useState(false)

    function updateActivity (e) {
        setActivity(e.target.value)
    }

    function updateRisk (e) {
        setRisk(e.target.value)
    }

    function updateMitigation (e) {
        setMitgation(e.target.value)
    }

    function handleClose () {
        props.setShow(false)
    }

    function loadNewValues () {
        props.triple.Activity = activity
        props.triple.Risk = risk
        props.triple.Mitigation = mitigation
    }

    function displayEditor () {
        if (props.triple._id) {
            return (
                <Form>
                    <Form.Group>
                        <Form.Label>Activity</Form.Label>
                        <Form.Control as="textarea" rows="3" value={activity} onChange={updateActivity}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Risk</Form.Label>
                        <Form.Control as="textarea" rows="3" value={risk} onChange={updateRisk}/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Mitigation</Form.Label>
                        <Form.Control as="textarea" rows="3" value={mitigation} onChange={updateMitigation}/>
                    </Form.Group>
                </Form>
            )
        }
    }

    useEffect(() => {
        if (props.triple._id !== currentID) {
            setActivity(props.triple.Activity)
            setRisk(props.triple.Risk)
            setMitgation(props.triple.Mitigation)
            setCurrentID(props.triple._id)
            setValuesInitialised(true)
        }
    }, [props.triple])

    useEffect(() => {
        if (edited) {
            setEdited(false)
            handleClose()
            loadNewValues()
        }
    }, [edited])

    return (
        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Triple</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><b>Edit the triple below</b></p>
                {displayEditor()}
                <span>
                    <Button variant="outline-primary" onClick={handleClose}>Cancel</Button>
                    <EditButton activity={activity} risk={risk} mitigation={mitigation} id={props.triple._id} setEdited={setEdited} db={props.db}/>
                </span>
            </Modal.Body>
        </Modal>
    )
}

export default EditModal
