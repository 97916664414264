import React from 'react'
import { Table } from 'react-bootstrap'

function LookupResults ({ results }) {
    if (results !== null) {
        if (results.length > 0) {
            return (
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>Activty</th>
                                <th>Risk</th>
                                <th>Mitigation</th>
                                <th>Cosine</th>
                                <th>Jaccard</th>
                                <th>Doc2Vec</th>
                                <th>Transformer</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        {results.map((hit, index) => {
                            return (
                                <tr key={index}>
                                    <td>{hit.Activity}</td>
                                    <td>{hit.Risk}</td>
                                    <td>{hit.Mitigation}</td>
                                    <td>{hit.Cosine_Score}</td>
                                    <td>{hit.Jaccard_Score}</td>
                                    <td>{hit.Doc2Vec_Score}</td>
                                    <td>{hit.Transformer_Score}</td>
                                    <td>{hit.Score}</td>
                                </tr>
                            )
                        })}
                    </Table>

                </>
            )
        } else { return <p>No results found</p> }
    } else {
        return (<p>Enter triple to lookup</p>)
    }
}

export default LookupResults
