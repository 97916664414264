import { Modal } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { getClient } from '../utils'
import { useKeycloak } from '@react-keycloak/web'

function JobInfo (props) {
    const [client, setClient] = useState(null)

    const { keycloak } = useKeycloak()

    useEffect(() => {
        const fetchClient = async () => {
            const res = await getClient(keycloak.tokenParsed?.internal_services_token, props.item._source.Client_ID)
            setClient(res)
        }

        fetchClient()
    }, [])

    return (
        <div style={{ border: '2px solid black' }}>
            <p>Client Name: {client ? client.clientName : 'Fetching...'}</p>
            <p>Client ID: {props.item._source.Client_ID}</p>
            <p>Vote: {props.item._source.Vote}</p>
            <p>Job Id: <a href={`https://internal-tools.intuety.io/status/${props.item._id}`}>{props.item._id}</a></p>
            <p>RA Status: {props.item._source.RA_Status}</p>
            <p>RA Remarks: {props.item._source.RA_Remarks}</p>
            <p>MS Status: {props.item._source.MS_Status}</p>
            <p>MS Remarks: {props.item._source.MS_Remarks}</p>
            <p>Time Created: {props.timeCreated.toTimeString()}</p>
            <p>Date Created: {props.timeCreated.toDateString()}</p>
            <p>Last Update: {props.lastUpdate.toTimeString()}</p>
        </div>
    )
}

function BarModal (props) {
    const handleClose = () => {
        props.setShow(false)
    }

    return (
    // ISSUE IS CALLING ASYN FUNCTION IN MAP FUNCTION

        <Modal show={props.show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Jobs uploaded  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.items
                    ? props.items.data?.map((item, index) => {
                        const timeCreated = new Date(item._source.Time_Created)
                        const lastUpdate = new Date(item._source.Last_Update)

                        return (
                            <JobInfo key={index} item={item} timeCreated={timeCreated} lastUpdate={lastUpdate} />
                        )
                    })
                    : null}
            </Modal.Body>
        </Modal>
    )
}

export default BarModal
