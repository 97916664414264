function replaceTriples (triples, field, oldPhrase, newPhrase, db, updateSearch, keycloak) {
    try {
        for (const triple of triples) {
            const oldString = triple._source[field]
            const regEx = new RegExp(oldPhrase, 'ig')
            const newString = oldString.replace(regEx, newPhrase)
            triple._source[field] = newString

            const body = JSON.stringify({
                Activity: triple._source.Activity,
                Risk: triple._source.Risk,
                Mitigation: triple._source.Mitigation
            })

            let url

            if (db === 'Live Database') {
                url = 'https://api.intuety.io/aurora/api/elasticdb/otg/update'
            } else if (db === 'Staging Database') {
                url = 'https://api.staging.intuety.io/aurora/api/elasticdb/otg/update'
            }

            const options = {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': keycloak.tokenParsed?.api_token
                },
                body: body
            }
            fetch(`${url}/${triple._id}`, options).then(response => {
                console.log(`Edit status code received: ${response.status}`)
            }).catch(err => {
                throw new Error(err)
            })
        }

        setTimeout(updateSearch, 1000)
    } catch (err) {
        alert(err)
    }
}

export default replaceTriples
