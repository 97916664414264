import React, { useState } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Button, Col, Container, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import DatabaseSelection from '../DatabaseSelection'
import findTriple from './FindTriple'
import replaceTriples from './ReplaceTriples'

const CheckboxHolder = styled.div`
    display: flex;
    align-items: center;
`

const CheckboxLabel = styled.label`
    margin-right: 0.5em;
`

const SearchRow = styled(Row)`
    display: flex;
    justify-content: space-between;
    padding: 1em;
`

const ReplaceRow = styled(Row)`
    display: flex;
    justify-content: flex-end;
    padding: 1em;
`

const ReplaceButton = styled(Button)`
    margin-left: 2em;
`

const CheckboxCell = styled.td`
    padding: auto;
`

function FindAndReplace () {
    const [db, setDB] = useState('Live Database')
    const [field, setField] = useState('Select Field')
    const [searchPhrase, setSearchPhrase] = useState('')
    const [replacePhrase, setReplacePhrase] = useState('')
    const [exactMatch, setExactMatch] = useState(true)
    const [matches, setMatches] = useState('Not Searched')
    const [selectedTriples, setSelectedTriples] = useState([])
    const [selectAll, setSelectAll] = useState(false)

    const { keycloak } = useKeycloak()

    const onSearch = () => {
        findTriple(field, searchPhrase, db, exactMatch, setMatches, keycloak)
        setSelectAll(false)
        setSelectedTriples([])
        const inputs = document.getElementsByTagName('input')

        for (const input of inputs) {
            if (input.type === 'checkbox') {
                if (input.id !== 'Select-All' && input.id !== 'Exact-Match') {
                    input.checked = false
                }
            }
        }
    }

    const onReplace = () => {
        replaceTriples(selectedTriples, field, searchPhrase, replacePhrase, db, onSearch, keycloak)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault()
        }
    }

    const updatedSelected = (id) => {
        const tmp = selectedTriples

        if (selectedTriples.includes(id)) {
            const pos = tmp.indexOf(id)
            tmp.splice(pos, 1)
            setSelectedTriples(tmp)
            setSelectAll(false)
        } else {
            tmp.push(id)
            setSelectedTriples(tmp)

            if (matches.length === selectedTriples.length) {
                setSelectAll(true)
            }
        }
    }

    const onSelectAll = () => {
        if (selectAll) {
            for (const match of matches) {
                document.getElementById(match._id).checked = false
            }

            setSelectedTriples([])
            setSelectAll(false)
        } else {
            const tmp = []

            for (const match of matches) {
                document.getElementById(match._id).checked = true
                tmp.push(match)
            }

            setSelectedTriples(tmp)
            setSelectAll(true)
        }
    }

    const displayMatches = () => {
        if (matches === 'Not Searched') {
            return <p>Nothing to show</p>
        } else if (matches.length === 0) {
            return <p>Search returned no results</p>
        } else {
            return (
                <>
                    <CheckboxLabel htmlFor="Select-All"> Select All </CheckboxLabel>
                    <input type="checkbox" id="Select-All" onChange={onSelectAll} checked={selectAll} />
                    <table>
                        <thead>
                            <tr>
                                <th>Selected</th>
                                <th>Activity</th>
                                <th>Risk</th>
                                <th>Mitigation</th>
                            </tr>
                        </thead>
                        <tbody>
                            {matches.map(match => {
                                return (
                                    <tr key={match._id}>
                                        <CheckboxCell><input type="checkbox" id={match._id} onChange={() => { updatedSelected(match) }} /></CheckboxCell>
                                        <td>{match._source.Activity}</td>
                                        <td>{match._source.Risk}</td>
                                        <td>{match._source.Mitigation}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>
            )
        }
    }

    return (
        <Container>
            <Row>
                <Col><h1 id="title"> Find and Replace </h1></Col>
                <Col><DatabaseSelection db={db} setDB={setDB} /></Col>
            </Row>
            <SearchRow>
                <DropdownButton
                    variant="outline-primary"
                    title={field}
                    onSelect={(e) => { setField(e) }}
                >
                    <Dropdown.Item eventKey="Activity">Activity</Dropdown.Item>
                    <Dropdown.Item eventKey="Risk">Risk</Dropdown.Item>
                    <Dropdown.Item eventKey="Mitigation">Mitigation</Dropdown.Item>
                </DropdownButton>
                <Form inline>
                    <Form.Control
                        type='text'
                        placeholder="Phrase"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => { setSearchPhrase(e.target.value) }}
                    />
                </Form>
                <CheckboxHolder>
                    <CheckboxLabel htmlFor="Exact-Match"> Exact Match </CheckboxLabel>
                    <input type="checkbox" id="Exact-Match" checked={exactMatch} onChange={() => { setExactMatch(!exactMatch) }} />
                </CheckboxHolder>
                <Button variant="outline-success" onClick={onSearch}>Search</Button>
            </SearchRow>
            <ReplaceRow>
                <Form inline>
                    <Form.Control
                        type='text'
                        placeholder="Phrase"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => { setReplacePhrase(e.target.value) }}
                    />
                </Form>
                <ReplaceButton variant="outline-success" onClick={onReplace}>Replace</ReplaceButton>
            </ReplaceRow>
            <Row>
                {displayMatches()}
            </Row>
        </Container>
    )
}

export default FindAndReplace
